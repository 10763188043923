import { Box, Grid, IconButton, Typography } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useModal } from 'hooks';
import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { getPersistedData, persistData } from 'utils/persist';

interface RulesSidePanelProps {
  title: string;
  children: ReactNode;
  onToggle?: () => void;
}
const RulesSidePanel: FC<RulesSidePanelProps> = ({ title, onToggle, children }) => {
  const { toggle: togglePlatforms, isOpen: isOpenPlatforms } = useModal(
    getPersistedData('directoryFilterVisibility') ?? false
  );

  const handleToggle = useCallback(() => {
    persistData('directoryFilterVisibility', !isOpenPlatforms);
    togglePlatforms();
    onToggle?.();
  }, [isOpenPlatforms, togglePlatforms, onToggle]);

  return (
    <Grid
      item
      sx={(t) => ({
        flex: 1,
        position: 'relative',
        maxWidth: !isOpenPlatforms ? 30 : 230,
        transition: 'all 0.2s',
        willChange: 'auto',
        borderTopLeftRadius: 12,
        borderTop: `1px solid ${t.palette.grey[300]}`,
        borderLeft: `1px solid ${t.palette.grey[300]}`,
        overflow: 'hidden',
        overflowY: 'auto',
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 230,
          left: 0,
          top: 0,
          py: 1.5,
          px: !isOpenPlatforms ? 0 : 1.5,
          overflow: 'hidden',
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          {isOpenPlatforms && (
            <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500 })}>{title}</Typography>
          )}
          <IconButton onClick={handleToggle}>
            {!isOpenPlatforms ? <MenuIcon sx={{ fontSize: 18 }} /> : <MenuOpenIcon sx={{ fontSize: 22 }} />}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            visibility: isOpenPlatforms ? 'visible' : 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export default RulesSidePanel;
