import { ListItemText, MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { useModal } from 'hooks';
import { useFormContext } from 'react-hook-form';
import { ConfirmBulkDuplicateDialog, ConfirmBulkEditDialog } from './RulesComponents';
import useChosenRules from '../../hooks/useChosenRules';
import { useAccessModify } from '../../hooks';
import {
  PauseMenuItem,
  RulesTableMenuContainer,
  ArchiveRulesMenuItem,
  UnarchiveRulesMenuItem,
  RunManuallyMenuItem,
} from '../../components';

const RulesTableActionMenu = () => {
  const { accessRules } = useAccessModify();

  const { isChosenRules } = useChosenRules();

  const { reset } = useFormContext();
  const { open: openBulkDuplicateModal, close: closeBulkDuplicateModal, isOpen: isBulkDuplicateModalOpen } = useModal();
  const { open: openBulkEditModal, close: closeBulkEditModal, isOpen: isBulkEditModalOpen } = useModal();

  const handleCloseBulkDuplicateDialog = useCallback(() => {
    closeBulkDuplicateModal();
    reset();
  }, [closeBulkDuplicateModal, reset]);

  const handleOpenBulkDuplicateDialog = useCallback(() => {
    openBulkDuplicateModal();
  }, [openBulkDuplicateModal]);

  const handleCloseBulkEditDialog = useCallback(() => {
    closeBulkEditModal();
    reset();
  }, [closeBulkEditModal, reset]);

  const handleOpenBulkEditDialog = useCallback(() => {
    openBulkEditModal();
  }, [openBulkEditModal]);

  return (
    <RulesTableMenuContainer>
      {({ closeMenu }) => (
        <>
          <PauseMenuItem network={'facebook'} closeMenu={closeMenu} />
          {accessRules.bulk && (
            <MenuItem disabled={!isChosenRules} onClick={handleOpenBulkDuplicateDialog}>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
          )}
          {accessRules.bulk && (
            <MenuItem disabled={!isChosenRules} onClick={handleOpenBulkEditDialog}>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          <RunManuallyMenuItem network={'facebook'} closeMenu={closeMenu} />
          <ArchiveRulesMenuItem network={'facebook'} closeMenu={closeMenu} />
          <UnarchiveRulesMenuItem network={'facebook'} status={'active'} closeMenu={closeMenu} />
          <UnarchiveRulesMenuItem network={'facebook'} status={'paused'} closeMenu={closeMenu} />
          <ConfirmBulkDuplicateDialog isOpen={isBulkDuplicateModalOpen} close={handleCloseBulkDuplicateDialog} />
          <ConfirmBulkEditDialog isOpen={isBulkEditModalOpen} close={handleCloseBulkEditDialog} />
        </>
      )}
    </RulesTableMenuContainer>
  );
};

export default RulesTableActionMenu;
