import { apiClient } from 'lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import type { Network } from 'types/shared';
import initApiNetwork from 'utils/initApiNetwork';
import { FB_RULES_LIST_KEY } from '../facebook/api/getRulesList';
import { GOOGLE_RULES_LIST_KEY } from '../google/api/getRulesList';

interface EditRulesResponse {
  status?: string;
}

export const editRules = async <TBulkParams>(params: TBulkParams, network: Network): Promise<EditRulesResponse> => {
  const apiNetwork = initApiNetwork(network);
  const res = await apiClient.patch<EditRulesResponse>(`${apiNetwork}/rules`, params);
  if (isAxiosError(res)) {
    const error = new Error('Edit rules error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseEditRulesOptions {
  config?: MutationConfig<typeof editRules>;
  network: Network;
}

export const useEditRules = <TBulkParams>({ config, network }: UseEditRulesOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: network === 'google' ? ['ga/channels'] : ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: network === 'google' ? [GOOGLE_RULES_LIST_KEY] : [FB_RULES_LIST_KEY],
        }),
      ]);
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: (params: TBulkParams) => editRules<TBulkParams>(params, network),
  });
};
