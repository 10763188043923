import { CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import Switch from 'components/common/Switch';
import { RuleStatus } from '../../components';
import type { Rule } from '../types';
import { format, parseISO } from 'date-fns';
import { useMyAccess } from 'features/users';
import { useChangeRuleStatus } from '../api/changeStatus';
import useListParams from 'hooks/useListParams';
import Checkbox from 'components/common/Checkbox';
import { BooleanParam, useQueryParam } from 'use-query-params';
import RulesActionList from './RulesActionList';
import { useFormContext } from 'react-hook-form';

interface RulesTableRowProps {
  rule: Rule;
}

const RulesTableRow: FC<RulesTableRowProps> = ({ rule }) => {
  const [flowsOpen] = useQueryParam('flowsOpen', BooleanParam);
  const access = useMyAccess({ id: rule.project_id });
  const { setValue } = useFormContext();

  const { tab } = useListParams();

  const { mutateAsync: changeStatus, isPending } = useChangeRuleStatus();

  const isRuleSwitchActive = rule.status === 'active';
  const isRuleArchived = rule.status === 'archived';

  const handleChangeStatus = async () => {
    try {
      await changeStatus({
        ruleId: rule.id,
        status: rule.status === 'active' ? 'paused' : 'active',
      });
      setValue(`rules.${rule.id}`, rule.status === 'active');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TableRow
      sx={{
        '& td': {
          p: 1,
        },
        '&:hover': {
          background: 'rgba(41, 121, 255, 0.08)',
        },
      }}
    >
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        align="center"
        sx={{ pr: '0px !important' }}
      >
        <Checkbox disabled={isPending} name={`rules.${rule.id}`} controlLabelSx={{ m: 0 }} />
      </TableCell>
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ pl: '4px !important', pr: '2px !important' }}
      >
        <Tooltip title={isRuleArchived && 'Unarchive to unpause'}>
          {isPending ? (
            <CircularProgress size={14} color={'inherit'} sx={{ ml: 1, mt: 0.5 }} />
          ) : (
            <Switch
              checked={isRuleSwitchActive}
              onChange={handleChangeStatus}
              disabled={isRuleArchived || !access?.rules.includes('rule_start-stop')}
              inputProps={{ 'aria-label': 'ant design' }}
            />
          )}
        </Tooltip>
      </TableCell>
      <TableCell sx={{ width: 300, maxWidth: flowsOpen ? 300 : 400, pl: '0 !important' }}>
        <Tooltip
          arrow
          title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>{rule.name}</Typography>}
          sx={{ cursor: 'default' }}
        >
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize: 13,
            }}
          >
            {rule.name}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ maxWidth: 64 }}>
        <RuleStatus status={(rule.status === 'active' ? rule.state : rule.status) as never} />
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}>
        {tab === 'paused' && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {rule.paused_at ? format(parseISO(rule.paused_at), 'dd.MM.yy HH:mm') : '-'}
          </Typography>
        )}
        {tab === 'archived' && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {rule.archived_at ? format(parseISO(rule.archived_at), 'dd.MM.yy HH:mm') : '-'}
          </Typography>
        )}
        {(tab === 'all' || tab === 'active') && rule.last_run && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {format(parseISO(rule.last_run), 'dd.MM.yy HH:mm')}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}>
        <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
          {rule.next_run ? format(parseISO(rule.next_run), 'dd.MM.yy HH:mm') : '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}></TableCell>
      <TableCell align="right">
        <RulesActionList ruleId={rule.id} ruleName={rule.name} ruleState={rule.state} />
      </TableCell>
    </TableRow>
  );
};

export default RulesTableRow;
