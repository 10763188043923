/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC } from 'react';
import { Suspense, useCallback, useEffect } from 'react';
import type { ModalProps, Option } from 'types/shared';
import { observer } from 'mobx-react-lite';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useModal } from 'hooks';
import useChosenRules from '../../hooks/useChosenRules';
import { useFilterQueryValues } from 'hooks';
import { useNavigate } from 'react-router';
import type { NotificationChannel, Rule, RulesListParams } from '../types';
import type { RuleStatusValue } from '../../types';
import { isEmpty, omit } from 'lodash-es';
import { toJS } from 'mobx';
import bulkDuplicateStore from '../stores/bulkDuplicateStore';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSearchParams } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { RuleStatusParameters } from './RulesComponents';
import { ProjectSelect } from 'features/projects';
import ChipInput from 'components/common/ChipInput';
import RuleUIFormActionBlock from '../../components/RuleUIFormActionBlock';
import Autocomplete from 'components/common/Autocomplete';
import RulesList from './bulk/RulesList';
import FlowsAutocomplete from './bulk/FlowsAutocomplete';
import ReplaceName from './bulk/ReplaceName';
import SuspenseFallbackSkeleton from './bulk/SuspenseFallbackSkeleton';
import FlowMetrics from './bulk/FlowMetrics';
import NotificationChannelsSelect from './bulk/NotificationChannelsSelect';
import bulkEditSchema from '../validation/bulkEditSchema';
import type { BulkEditPayload } from '../api/bulkEdit';
import { useBulkEdit } from '../api/bulkEdit';
import NoSelectedRules from './bulk/NoSelectedRules';
import { useRuleNavigation } from '../hooks';
import { getRulesListOptions } from '../api/getRulesList';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DiscardChangesDialog } from 'features/rules';

interface FormData {
  name: string | undefined;
  project_id: number | undefined;
  flow_id: number | undefined;
  notification_channels: NotificationChannel[] | undefined;
  facebook_ad_account_ids: string[];
  status: RuleStatusValue;
  fields: string[];
}

const bulkFieldOptions: Option[] = [
  { label: 'Status', value: 'status' },
  { label: 'Name', value: 'name' },
  { label: 'Flow', value: 'flow_id' },
  { label: 'Account', value: 'facebook_ad_account_ids' },
  { label: 'Project', value: 'project_id' },
  { label: 'Notification channel', value: 'notification_channels' },
];

const BulkEditForm: FC<Pick<ModalProps, 'close'>> = observer(({ close }) => {
  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar();
  const { isOpen: isOpenDiscard, open: openDiscard, close: closeDiscard } = useModal();
  const { rulesIds, isAllRulesChosen } = useChosenRules();
  const { ruleListURL } = useRuleNavigation();

  const params = useFilterQueryValues<RulesListParams>();
  const navigate = useNavigate();

  const { data: rules } = useSuspenseQuery({
    ...getRulesListOptions(omit(params, ['paginationRelatedParams'])),
    select: (data) => {
      const rulesData = (data as { data: Rule[] }).data;
      return isAllRulesChosen ? rulesData : rulesData.filter(({ id }) => rulesIds.includes(id));
    },
  });

  const storeRules = toJS(bulkDuplicateStore.list);

  useEffect(() => {
    !isEmpty(rules) && bulkDuplicateStore.initialRules(rules);
  }, [rules]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: '',
      project_id: undefined,
      flow_id: undefined,
      notification_channels: undefined,
      facebook_ad_account_ids: [],
      fields: [],
      status: undefined,
    },
    // @ts-expect-error schema fix
    resolver: yupResolver(bulkEditSchema),
  });

  const { reset, setValue, handleSubmit, watch } = formMethods;

  const [projectField, fields] = watch(['project_id', 'fields']);

  useEffect(() => {
    if (fields.includes('flow_id') && !fields.includes('project_id')) {
      setValue('fields', [...fields, 'project_id']);
    }
  }, [fields, setValue]);

  useEffect(() => {
    if (projectField) {
      setValue('flow_id', undefined, { shouldValidate: false });
    }
  }, [projectField, setValue]);

  const { mutateAsync: edit, isPending } = useBulkEdit({
    config: {
      onSuccess: (_, variables: BulkEditPayload[]) => {
        const { flow_id, project_id, status } = variables[0];

        const ruleParams: Record<string, string> = {
          ...(flow_id && { listId: String(flow_id) }),
          ...(project_id && { project: String(project_id) }),
          ...(status && { statusState: String(status) }),
          ...(status && { tab: String(status) }),
          platform: String(params?.platform_id ?? 1),
        };

        navigate({
          pathname: ruleListURL,
          search: createSearchParams(ruleParams).toString(),
        });
        reset();
        close();
        onShowAlert('Success');
      },
      onError: (err) => {
        onShowErrorAlert((err?.message || err?.cause?.message) ?? 'Error');
      },
    },
  });

  const onDiscard = useCallback(() => {
    reset();
    closeDiscard();
    close();
  }, [reset, close, closeDiscard]);

  const onCloseDiscard = useCallback(() => {
    closeDiscard();
  }, [closeDiscard]);

  const onSubmit = async (data: FormData) => {
    const normalizedData = omit(data, ['name', 'fields', 'replaceWith']);

    const payload: BulkEditPayload[] = storeRules.map(({ id, name }) => ({
      id,
      name,
      ...normalizedData,
    }));

    await edit(payload);
  };

  return (
    <Box
      sx={{ display: 'flex', flexGrow: 1, height: '100%', minWidth: 990, overflow: 'auto', flexWrap: 'nowrap', pt: 2 }}
    >
      {rules.length ? (
        <FormProvider {...formMethods}>
          <Box
            sx={(t) => ({
              display: 'flex',
              width: '100%',
              p: 0,
              flex: 1,
              borderRight: `1px solid ${t.palette.divider}`,
            })}
          >
            <TableContainer sx={{ width: '100%', p: 0, flex: 1, pr: 5 }}>
              <Table sx={{ '& th': { px: 0 }, '& td': { py: 0, px: 1 } }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" colSpan={2} sx={{ '&': { py: 0, verticalAlign: 'top' } }}>
                      Rules to be updated
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RulesList mode="edit" />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              pt: 0,
              pb: 16,
              pr: 8,
              pl: 5,
              flex: 1,
              width: '35%',
              maxWidth: 550,
              overflow: 'hidden',
              overflowY: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Parameters to edit
              </Typography>
            </Box>
            <Autocomplete
              options={bulkFieldOptions}
              allSelectable
              name="fields"
              id="fields"
              label="Fields"
              innerLabel
              tooltipTitle="Please select fields to edit"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
              {fields.includes('status') && (
                <RuleStatusParameters name="status" sx={{ flexDirection: 'row', justifyContent: 'space-between' }} />
              )}
              {fields.includes('name') && <ReplaceName />}
              {fields.includes('project_id') && (
                <Suspense fallback={<SuspenseFallbackSkeleton />}>
                  <ProjectSelect
                    onlyUsersProjects
                    name="project_id"
                    label="Project"
                    required
                    filter={(p) => !!p.value}
                  />
                </Suspense>
              )}
              {fields.includes('flow_id') && (
                <Box>
                  <FlowsAutocomplete name="flow_id" projectFieldName="project_id" />
                </Box>
              )}
              {fields.includes('facebook_ad_account_ids') && (
                <ChipInput name="facebook_ad_account_ids" label="Account" required />
              )}
              {fields.includes('notification_channels') && (
                <Suspense fallback={<SuspenseFallbackSkeleton />}>
                  <NotificationChannelsSelect name="notification_channels" label="Notification channel" required />
                </Suspense>
              )}
              <FlowMetrics />
            </Box>
          </Box>
          <DiscardChangesDialog isOpen={isOpenDiscard} onClose={onCloseDiscard} onDiscard={onDiscard} />
          <RuleUIFormActionBlock
            mode={['update']}
            project={{ id: projectField!, name: '' }}
            isPending={isPending}
            onDiscard={openDiscard}
            hasChanges={true}
            onSave={handleSubmit(onSubmit, (err) => {
              console.log('Validation error: ', err);
            })}
            submitTitle="Update"
          />
        </FormProvider>
      ) : (
        <NoSelectedRules />
      )}
    </Box>
  );
});

export default BulkEditForm;
