import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Location } from 'react-router';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { NotificationMessage, RuleStatusValue, RuleTypeMutation } from '../../types';
import { useEnqueueSnackbar, useModal, useScrollIntoTabView } from 'hooks';
import {
  RuleEditTitle,
  TabViewContainer,
  DiscardChangesDialog,
  RuleUpdatedMessage,
  RuleUIFormActionBlock,
} from '../../components';
import BasicValues from './BasicValues';
import { basicValuesSchema } from '../validation';
import { getGoogleRuleDetailsOptions } from '../api/getGoogleRuleDetails';
import { useRuleNavigation } from '../../hooks';
import type { LevelValue } from '../types';
import normalizeObject from '../../utils/normalizeObject';
import GoogleRuleScriptDialog from './GoogleRuleScriptDialog';
import type { RuleType } from '../types';
import { useUpdateGoogleRule } from '../api/updateGoogleRule';
import type { UpdateRulePayload } from '../api/types';
import CircularSpinner from '../../../../components/common/CircularSpinner';
import { useCreateGoogleRule } from '../api/createGoogleRule';
import type { GoogleRuleResponseData } from '../api/types';

export interface BasicGoogleValues {
  name: string;
  project: number;
  ruleStatus: string;
  level: LevelValue;
  type: RuleType;
  description: string;
  notificationMessage: NotificationMessage;
  ad_account_ids: string[];
}

interface RuleEditProps {
  mode: RuleTypeMutation;
}

const EditGoogleRule: FC<RuleEditProps> = observer(({ mode }) => {
  const isModeDuplicate = mode === 'duplicate';
  const isModeEdit = mode === 'edit';
  const { ruleId } = useParams<{ ruleId: string }>();
  const { data: rule } = useSuspenseQuery(getGoogleRuleDetailsOptions(Number(ruleId)));
  const navigate = useNavigate();
  const { state: stateLocation } = useLocation();
  const { isOpen, open, close } = useModal();
  const { onShowAlert } = useEnqueueSnackbar();
  const [isChangesApplied, setIsChangesApplied] = useState(false);
  const { ruleListURL, ruleEditURL } = useRuleNavigation();

  const scrollContainerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { tab, handleChangeTab } = useScrollIntoTabView(scrollContainerRef, contentRef);

  const getInitialValues = useMemo(
    () => ({
      name: rule.name,
      project: rule.project_id,
      ruleStatus: rule.status,
      description: rule.description,
      level: rule.data.level,
      type: rule.data.type,
      notificationMessage: {
        title: rule.data.notification_message?.title,
        body: rule.data.notification_message?.body,
      },
      ad_account_ids: rule.data.ad_account_ids.map(String),
    }),
    [rule]
  );

  const formMethods = useForm<BasicGoogleValues>({
    defaultValues: getInitialValues,
    // @ts-expect-error schema correction
    resolver: yupResolver(basicValuesSchema),
  });

  const { handleSubmit, reset, getFieldState } = formMethods;

  const isNameDirty = getFieldState('name').isDirty;

  useEffect(() => {
    formMethods.reset(getInitialValues);

    // if (isEmpty(filterStore.filterGroups)) {
    //   filterStore.setFilterGroups(rule.data.filters);
    // }
    // if (isEmpty(tasksFormStore.tasksForm)) {
    //   tasksFormStore.initialTasks(rule.data.tasks);
    // }
    // if (timeTableStore.isEmpty) {
    //   if (rule.data.schedule_interval) {
    //     timeTableStore.setInitialScheduleInterval(rule.data.schedule_interval);
    //   } else if (rule.data.timetable) {
    //     timeTableStore.setInitialData(rule.data.timetable);
    //   }
    // }

    return () => {
      // filterStore.reset();
      // tasksFormStore.reset();
      // timeTableStore.reset();
      reset();
      window.history.replaceState({}, ''); // clear location state
    };
  }, [formMethods, getInitialValues, reset, rule]);

  const { mutateAsync: updateRule, isPending: isUpdating } = useUpdateGoogleRule({
    config: {
      onSuccess: () => {
        onShowAlert('Changes saved successfully', { delayAlert: 3500 });
        setIsChangesApplied((v) => !v);
      },
    },
  });

  const { mutateAsync: duplicateRule, isPending: isDuplicating } = useCreateGoogleRule({
    config: {
      onSuccess: (data: GoogleRuleResponseData) => {
        navigate(ruleEditURL(data.id), { replace: true, state: { skipBlocker: true, isDuplicated: true } });
        onShowAlert('Rule duplicated', { delayAlert: 3500 });
      },
    },
  });

  const blocker = useBlocker(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location<{ skipBlocker?: boolean }>;
    }) => {
      const { skipBlocker } = { ...nextLocation.state };
      // return !skipBlocker && hasEditFormChanges && currentLocation.pathname !== nextLocation.pathname;
      return !skipBlocker && currentLocation.pathname !== nextLocation.pathname;
    }
  );

  const isBlocked = blocker.state === 'blocked';

  const getSubmitPayload = ({
    ruleStatus,
    description,
    project,
    level,
    name,
    ad_account_ids,
    notificationMessage,
    type,
  }: BasicGoogleValues): UpdateRulePayload => {
    return {
      id: Number(ruleId),
      project_id: project,
      name,
      status: ruleStatus as RuleStatusValue,
      description,
      data: {
        ...rule.data,
        type,
        filters: [],
        tasks: [],
        level: level as LevelValue,
        ad_account_ids: ad_account_ids.map(String),
        notification_message: {
          title: notificationMessage.title,
          body: notificationMessage.body,
        },
      },
    };
  };

  const onSubmit = async (data: BasicGoogleValues) => {
    const payload = getSubmitPayload(data);

    try {
      if (isModeEdit) {
        await updateRule(payload);
      }

      if (isModeDuplicate) {
        // const { id, ...restParams } = payload;
        // await duplicateRule({
        //   ...restParams,
        //   network_id: rule.network_id,
        //   action_types_ids: rule.action_types.map((at) => at.id),
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDiscard = () => {
    if (isBlocked) {
      blocker.proceed();
    }
    reset();
    JSON.stringify(normalizeObject(rule.data), null, 2);
    close();
    navigate(ruleListURL);
  };

  const onClose = () => {
    if (isBlocked) {
      blocker.reset();
    }
    close();
  };

  const openLogs = () => {
    window.open(rule.logs_s3_folder_url, '_blank');
  };

  const isLoading = isUpdating || isDuplicating;

  if (isLoading) {
    return <CircularSpinner title={isDuplicating ? 'Duplicating rule' : 'Updating rule'} />;
  }

  return (
    <FormProvider {...formMethods}>
      <DiscardChangesDialog isOpen={isOpen || isBlocked} onClose={onClose} onDiscard={onDiscard} />
      <GoogleRuleScriptDialog isOpen={isOpen} onClose={close} rule={rule} />
      <RuleUIFormActionBlock
        mode={['update', 'create-to-some']}
        project={{ id: rule.project_id, name: rule.project_name }}
        isPending={isLoading}
        onDiscard={onDiscard}
        hasChanges
        onSave={handleSubmit(onSubmit)}
        submitTitle={isModeDuplicate ? 'Duplicate' : 'Save changes'}
      />
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <RuleEditTitle name={rule.name} status={rule.status as RuleStatusValue} state={rule.state} mode={mode} />
          {isChangesApplied && <RuleUpdatedMessage text="Rule will be updated in 10 minutes or less." />}
          {stateLocation?.isDuplicated && (
            <RuleUpdatedMessage text="New rule has been created. It will be available to perform in 10 minutes or less." />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
              <Tab value="basics" label="Basics" />
              <Tab value="filters" label="Filters" />
              <Tab value="tasks" label="Tasks" />
              <Tab value="timetable" label="Timetable" />
            </Tabs>

            <Box>
              <Button sx={{ textDecoration: 'underline', textTransform: 'inherit' }} onClick={openLogs}>
                <OpenInNewIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle2">S3 Logs</Typography>
              </Button>
              <Button sx={{ textDecoration: 'underline', textTransform: 'inherit' }} onClick={open}>
                Rule script
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          ref={scrollContainerRef}
          sx={{ display: 'flex', flexGrow: 1, position: 'relative', overflow: 'auto', mr: -3 }}
        >
          <Box
            ref={contentRef}
            sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', gap: 3, width: '100%', pr: 3 }}
          >
            <TabViewContainer id="basics">
              <BasicValues rule={rule} mode={mode} />
            </TabViewContainer>
            <TabViewContainer id="filters">filters</TabViewContainer>
            <TabViewContainer id="tasks">tasks</TabViewContainer>
            <TabViewContainer id="timetable">timetable</TabViewContainer>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
});

export default EditGoogleRule;
