import { Box, Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import useListParams from 'hooks/useListParams';
import type { TabsParam } from 'types/shared';
import { useFormContext } from 'react-hook-form';
import { STATUS_TABS } from '../../constants';

const RulesStatusTabs = () => {
  const { tab, setTab, setStatusState, setPauseDate, setArchiveDate, setNewFlow, setPlatform, setListId } =
    useListParams();
  const { setValue } = useFormContext();

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newStatus: TabsParam) => {
      setTab(newStatus);

      setValue('pauseDate', undefined);
      setPauseDate(undefined);
      setValue('archiveDate', undefined);
      setArchiveDate(undefined);
      setNewFlow(undefined);
      setPlatform(undefined);
      setListId(undefined);

      if (newStatus !== 'all') {
        setStatusState([newStatus]);
      } else {
        setStatusState(undefined);
      }
    },
    [setTab, setValue, setArchiveDate, setPauseDate, setStatusState, setPlatform, setListId, setNewFlow]
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tab} onChange={handleChange}>
        {STATUS_TABS.map((tab) => (
          <Tab key={`${tab.id}:${tab.name}`} label={tab.name} value={tab.name.toLowerCase()} />
        ))}
      </Tabs>
    </Box>
  );
};

export default RulesStatusTabs;
