import type { FC } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import type { SettingsEntry } from '../facebook/api';

interface IProps {
  actionTypes: SettingsEntry[];
  flows?: SettingsEntry[];
  team?: string;
}

const getSummary = ({ actionTypes, flows, team }: IProps) => {
  const summary = [{ title: 'Action type:', value: actionTypes.map(({ name }) => name).join(', ') }];

  if (flows) {
    summary.push({ title: 'Flows:', value: flows.map(({ name }) => name).join(', ') });
  }

  if (team) {
    summary.push({ title: 'Team:', value: team });
  }

  return summary;
};

const BasicSummary: FC<IProps> = (props) => {
  const summary = getSummary(props);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="subtitle1">Summary</Typography>
      <Box sx={{ px: 2, background: 'rgba(249, 250, 251, 1)', borderRadius: 1.5 }}>
        <List sx={{ '&': { display: 'flex', flexDirection: 'column', gap: 1 } }}>
          {summary.map((item) => (
            <ListItem disablePadding key={`summaryListItem:${item.value}`}>
              <ListItemText secondary={item.title} sx={(t) => ({ flex: 1, fontSize: t.typography.body1.fontSize })} />
              <ListItemText primary={item.value} sx={(t) => ({ flex: 1, fontSize: t.typography.body1.fontSize })} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default BasicSummary;
