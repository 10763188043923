import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const useChosenRules = () => {
  const { reset, setValue } = useFormContext();
  const chosenRules = useWatch({ name: 'rules' }) as Record<string, boolean>;
  const isAllRulesChosen = useWatch({ name: 'all_rules_chosen' }) as boolean;
  const rulesIds = chosenRules
    ? Object.entries(chosenRules)
        .filter(([, value]) => value)
        .map(([key]) => parseInt(key))
    : [];
  const isChosenRules = Object.values(chosenRules ?? {}).includes(true);
  const isIndeterminate = isChosenRules && Object.values(chosenRules ?? {}).includes(false);

  const onResetRules = useCallback(() => {
    reset({
      rules: {},
      all_rules: false,
    });
  }, [reset]);

  const onSetRules = useCallback(
    (value: Record<string, boolean>) => {
      setValue('rules', { ...value });
    },
    [setValue]
  );

  const onSetIsAllRules = useCallback(
    (value: boolean) => {
      setValue('all_rules_chosen', value);
    },
    [setValue]
  );

  return {
    isIndeterminate,
    isChosenRules,
    chosenRules,
    rulesIds,
    onResetRules,
    onSetRules,
    onSetIsAllRules,
    isAllRulesChosen,
  };
};

export default useChosenRules;
