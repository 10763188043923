import { useModal } from 'hooks';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSyncPlatforms } from '../../api';
import type { Option } from 'types/shared';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import Select from 'components/common/Select';
import { Add } from '@mui/icons-material';
import { FlowsModify } from '../FlowsModify';
import { AddFlowForm } from '../BulkDuplicateForm';
import SuspenseFallbackSkeleton from './SuspenseFallbackSkeleton';

interface FlowsAutocompleteProps {
  name: string;
  projectFieldName: string;
}

const FlowsAutocomplete: FC<FlowsAutocompleteProps> = ({ name, projectFieldName }) => {
  const { open, close, isOpen } = useModal();
  const { trigger } = useFormContext();
  const projectId = useWatch({ name: projectFieldName });

  const { data: flowsList, isLoading } = useSyncPlatforms<Option[]>({
    params: {
      project_id: projectId,
    },
    config: {
      enabled: !!projectId,
      select: (data): Option[] => {
        return data.platforms.flatMap(({ list }) =>
          list.filter((i) => `${i.id}` !== 'not_assigned').map(({ id, name }) => ({ value: id, label: name }))
        );
      },
    },
  });

  const handleAddFlow = useCallback(() => {
    open();
  }, [open]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!isLoading ? (
        <>
          <Select
            name={name}
            label="Flow"
            required
            selectAll={false}
            options={flowsList ?? []}
            disabled={!projectId}
            onBlur={() => trigger('flow')}
            footerElement={
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  py: 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Button startIcon={<Add />} variant="text" size="small" color="primary" onClick={handleAddFlow}>
                  Add flow
                </Button>
              </Box>
            }
          />
          <FlowsModify isOpen={isOpen} close={close} modalProps={{ sx: { zIndex: 1400 } }}>
            <AddFlowForm close={close} project={projectId} />
          </FlowsModify>
        </>
      ) : (
        <SuspenseFallbackSkeleton />
      )}
    </Box>
  );
};

export default FlowsAutocomplete;
