import { useTheme } from '@mui/material';
import { orange } from '@mui/material/colors';
import type { RuleStatusValue } from 'features/rules';

const useMetricRulesColors = ({ status }: { status: RuleStatusValue }) => {
  const t = useTheme();
  const colors = {
    active: {
      borderColor: t.palette.primary.main,
      backgroundColor: 'rgba(41, 121, 255, 0.08)',
      textColor: t.palette.primary.main,
    },
    paused: {
      borderColor: orange[600],
      backgroundColor: 'rgba(255, 249, 240, 1)',
      textColor: orange[800],
    },
    archived: {
      borderColor: t.palette.grey[500],
      backgroundColor: t.palette.grey[50],
      textColor: t.palette.text.primary,
    },
    default: {
      borderColor: t.palette.grey[500],
      backgroundColor: t.palette.grey[50],
      textColor: t.palette.text.primary,
    },
  };

  const colorSchema = colors[status] ? colors[status] : colors.default;

  return {
    colorSchema,
  };
};

export default useMetricRulesColors;
