import { useCurrentNetwork } from 'hooks';

const useRuleNavigation = () => {
  const network = useCurrentNetwork();

  const subPath = `/networks/${network}`;

  const ruleListURL = subPath + '/rules';
  const ruleEditURL = (id: number) => subPath + `/rules/edit/${id}`;
  const ruleDuplicateURL = (id: number) => subPath + `/rules/duplicate/${id}`;

  return {
    ruleListURL,
    ruleEditURL,
    ruleDuplicateURL,
  };
};

export default useRuleNavigation;
