import useListParams from 'hooks/useListParams';
import { STATE_VALUES, STATUS_VALUES, WEEK_DAY_VALUES } from '../constants';
import { useCurrentNetwork, usePagination } from 'hooks';

const useFilterQueryValues = <TParams = object>() => {
  const network = useCurrentNetwork();
  const {
    q,
    platform,
    listId,
    project,
    statusState,
    weekday,
    timeTo,
    timeFrom,
    archiveDate,
    pauseDate,
    level,
    action,
    team,
    orderBy,
    desc,
    tab,
    groups,
    channel,
  } = useListParams();

  const { perPage, page, handleRowsPerPageChange, resetPagination, setPerPage, handlePageChange } = usePagination(
    [
      q,
      platform,
      listId,
      project,
      statusState,
      weekday,
      timeTo,
      timeFrom,
      archiveDate,
      pauseDate,
      level,
      action,
      team,
      orderBy,
      desc,
      groups,
    ],
    100
  );

  const initialStatusValue = () => {
    const statusValue = statusState ? (statusState as string[]).filter((s) => STATUS_VALUES.includes(s)) : undefined;
    return tab === 'active' && !statusValue?.includes('active') ? [...(statusValue ?? []), 'active'] : statusValue;
  };

  const initialChannelValue = () => {
    return channel ? [channel] : undefined;
  };

  if (network === 'google') {
    const status = initialStatusValue();
    return {
      name: q?.length ? q : undefined,
      project_id: project === 'all_projects' ? undefined : parseInt(project),
      status: status,
      state: statusState ? (statusState as string[]).filter((s) => STATE_VALUES.includes(s)) : undefined,
      channel: initialChannelValue(),
      limit: perPage,
      offset: page * perPage,
      order_by: orderBy,
      desc,
      paginationRelatedParams: {
        page,
        handleRowsPerPageChange,
        resetPagination,
        setPerPage,
        handlePageChange,
      },
    } as TParams;
  }

  // if (network === 'facebook')
  return {
    name: q?.length ? q : undefined,
    project_id: project === 'all_projects' ? undefined : parseInt(project),
    platform_id: platform,
    list_id: (listId as string[]) ?? undefined,
    action_type_id: action ? (action as string[]) : undefined,
    team_id: team ? (team as string[]) : undefined,
    weekday: weekday.map((i) => WEEK_DAY_VALUES[i! - 1]) ?? undefined,
    time_from: timeFrom ?? undefined,
    time_to: timeTo ?? undefined,
    pause_date_from: pauseDate?.split(' - ')[0] ?? undefined,
    pause_date_to: pauseDate?.split(' - ')[1] ?? pauseDate?.split(' - ')[0] ?? undefined,
    archive_date_from: archiveDate?.split(' - ')[0] ?? undefined,
    archive_date_to: archiveDate?.split(' - ')[1] ?? archiveDate?.split(' - ')[0] ?? undefined,
    status: initialStatusValue(),
    state: statusState ? (statusState as string[]).filter((s) => STATE_VALUES.includes(s)) : undefined,
    level: level ? (level as string[]) : undefined,
    group_id: groups ? (groups as string[]) : undefined,
    limit: perPage,
    offset: page * perPage,
    order_by: orderBy,
    desc,
    paginationRelatedParams: {
      page,
      handleRowsPerPageChange,
      resetPagination,
      setPerPage,
      handlePageChange,
    },
  } as TParams;
};

export default useFilterQueryValues;
