import * as yup from 'yup';
import { queryClient } from 'lib/react-query';
import type { NotificationChannel } from '../types';

const notificationChannelSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
      channel_type: yup.string().required(),
    })
  )
  .default(null)
  .nullable();

const bulkEditSchema = yup.object().shape({
  name: yup.string().when('fields', {
    is: (fields: string[]) => fields.includes('name'),
    then: (schema) => schema,
    otherwise: (schema) => schema.strip(),
  }),
  replaceWith: yup.string().when('fields', {
    is: (fields: string[]) => fields.includes('name'),
    then: (schema) => schema,
    otherwise: (schema) => schema.strip(),
  }),
  project_id: yup.number().when('fields', {
    is: (fields: string[]) => fields.includes('project_id'),
    then: (schema) => schema.required("Can't be empty"),
    otherwise: (schema) => schema.notRequired().strip(),
  }),
  flow_id: yup.string().when('fields', {
    is: (fields: string[]) => fields.includes('flow_id'),
    then: (schema) => schema.required('Choose project to select flow'),
    otherwise: (schema) => schema.notRequired().strip(),
  }),
  notification_channels: notificationChannelSchema.when('fields', {
    is: (fields: string[]) => fields.includes('notification_channels'),
    then: (schema) => {
      const cachedNotifications = queryClient.getQueryData<NotificationChannel[]>(['notification_channels']);

      return schema.transform((id) => {
        const notification = cachedNotifications?.find((cn) => cn.id === id);

        return notification ? [notification] : [];
      });
    },
    otherwise: (schema) => schema.notRequired().strip(),
  }),
  facebook_ad_account_ids: yup.array().when('fields', {
    is: (fields: string[]) => fields.includes('facebook_ad_account_ids'),
    then: (schema) => schema.required("Can't be empty"),
    otherwise: (schema) => schema.notRequired().strip(),
  }),
  status: yup
    .string()
    .oneOf(['paused', 'archived', 'active'])
    .when('fields', {
      is: (fields: string[]) => fields.includes('status'),
      then: (schema) => schema.required("Can't be empty"),
      otherwise: (schema) => schema.notRequired().strip(),
    }),
  fields: yup.array().of(yup.string()).min(1, 'Please select at least 1 field to edit').required(),
});

export default bulkEditSchema;
