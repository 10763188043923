import { Titled } from 'react-titled';
import { Networks } from 'features/networks';

const NetworksPage = () => {
  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Networks />
    </>
  );
};

export default NetworksPage;
