import type { SyntheticEvent } from 'react';
import { useState, useEffect } from 'react';

const useScrollIntoTabView = (
  scrollContainerRef: React.RefObject<HTMLElement>,
  contentRef: React.RefObject<HTMLDivElement>
) => {
  const [tab, setTab] = useState('basics');

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current || !contentRef.current) return;

      const scrollPosition = scrollContainerRef.current.scrollTop + window.innerHeight / 3; // I adjust the offset as needed.. I find 1/3 the height of the window to be sufficient
      const children: HTMLCollection = contentRef.current.children;

      Array.from(children).forEach((child) => {
        const elem = child as HTMLElement; // casting into specific type
        if (elem.offsetTop <= scrollPosition && elem.offsetTop + elem.offsetHeight > scrollPosition) {
          setTab(child.id);
        }
      });
    };
    scrollContainerRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef, contentRef]);

  const handleChangeTab = (_: SyntheticEvent, newValue: string) => {
    setTab(newValue);
    const anchor = document.querySelector(`#${newValue}`);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return { tab, setTab, handleChangeTab };
};

export default useScrollIntoTabView;
