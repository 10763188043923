import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import { useRunRules } from '../api/runRules';
import useRulesQueryParams from './useRulesQueryParams';
import type { Network } from 'types/shared';
import type { BulkActionRulesParams } from '../types';

interface UseBulkRunManually {
  network: Network;
  onSuccess?: () => void;
  onError?: () => void;
}

const useBulkRunManually = <TRulesFiltersParams>({ onSuccess, onError, network }: UseBulkRunManually) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setValue, reset } = useFormContext();
  const { tab } = useListParams();
  const { isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams<BulkActionRulesParams<TRulesFiltersParams>>();

  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar({
    delay: 5000,
  });

  const runRulesMutation = useRunRules<TRulesFiltersParams>({
    network,
    config: {
      onSuccess: (_, variables) => {
        const vars = variables as BulkActionRulesParams<TRulesFiltersParams>;
        setValue('all_rules_switched', tab === 'active');
        onShowAlert(vars.rules_ids && vars.rules_ids?.length > 1 ? 'Rules are running' : 'Rule is running');
        reset();
        onSuccess?.();
      },
      onError: (err) => {
        onShowErrorAlert(err.cause?.message ?? 'Error');
        setErrorMessage(err.cause?.message);
        onError && onError();
      },
    },
  });

  const onRunManually = useCallback(() => {
    closeSnackbar();
    runRulesMutation.mutate(rulesQueryParams as BulkActionRulesParams<TRulesFiltersParams>);
  }, [runRulesMutation, rulesQueryParams]);

  return {
    errorMessage,
    isChosenRules,
    isPending: runRulesMutation.isPending,
    isSuccess: runRulesMutation.isSuccess,
    onRunManually,
  };
};

export default useBulkRunManually;
