import type { ChangeEvent, FC, HTMLInputTypeAttribute, ReactElement } from 'react';
import { Suspense, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import {
  SubdirectoryArrowRight,
  Percent,
  Tag as TagIcon,
  AltRoute as AltRouteIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import type { ActionIndexProps, ActionProps, ActionFieldsOptions } from '../types';
import tasksFormStore from '../stores/tasksFormStore';

import RuleCommonAutocomplete from './RuleCommonAutocomplete';
import type { Option, OptionValue } from 'types/shared';
import { REST_ACTIONS, SUB_ACTIONS } from '../constants';
import { useConditionsConstants } from '../api/getConditionsConstants';
import actionFieldsVisibility from '../utils/actionFieldsVisibility';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { ActionsTypeEntry } from '../../api/getActionsType';
import { useActionsType } from '../../api/getActionsType';
import { useNotificationChannels } from '..';
import Autocomplete from 'components/common/Autocomplete';
import { isEmpty } from 'lodash-es';

export const SuspenseFallbackSkeleton = () => (
  <Box sx={{ p: 0.5 }}>
    <Skeleton
      width={'100%'}
      height={26}
      sx={(t) => ({
        transform: 'translateY(0)',
        m: 0,
        borderRadius: t.borderRadius.md,
      })}
    />
  </Box>
);

export const RuleEditAutoCompleteStyled = styled(RuleCommonAutocomplete)({
  '& .MuiInputBase-root': { minHeight: 36, maxHeight: 36, border: 'none' },
  '& .MuiOutlinedInput-root fieldset': { border: 'none', minHeight: 36, maxHeight: 36, top: 0 },
  '&.MuiAutocomplete-listbox': {
    wordBreak: 'break-all',
  },
  '& .MuiAutocomplete-option': {
    padding: '6px 16px',
    width: '100%',
    wordBreak: 'break-all',
    lineHeight: 1.25,
    boxSizing: 'border-box',
  },
});

export const DeleteAction: FC<ActionIndexProps> = observer(({ indexTask, indexVariant, indexAction }) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const handleDelete = () => {
    const handleDeletion = tasksFormStore.deleteAction({ indexTask, indexVariant, indexAction });

    onShowAlert('Action deleted', {
      delayAlert: 3500,
      onUndo: () => {
        handleDeletion.undo({ indexTask, indexVariant });
        onShowInfoAlert('Action recovered', {
          isPending: false,
          delayAlert: 3500,
        });
      },
    });
  };

  return (
    <IconButton size="medium" sx={{ p: 0, height: 24 }} onClick={handleDelete} className="visiblyDeleteButton">
      <DeleteIcon sx={{ fontSize: 24, mb: -0.3 }} />
    </IconButton>
  );
});

export const ActionFormContainerTitle: FC<
  ActionIndexProps & {
    title: string;
  }
> = ({ title, indexTask, indexVariant, indexAction }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <AltRouteIcon sx={(t) => ({ color: t.palette.primary.main, fontSize: t.typography.h4 })} />
          <Typography variant="subtitle2" sx={(t) => ({ color: t.palette.primary.main, fontWeight: 500 })}>
            {title}
          </Typography>
        </Box>
      </Box>
      <DeleteAction indexTask={indexTask} indexAction={indexAction} indexVariant={indexVariant} />
    </Box>
  );
};

export const ActionToggleButton: FC<{ onClick: () => void; isActive: boolean; icon: ReactElement }> = ({
  onClick,
  isActive,
  icon,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={(t) => {
        return {
          '&': {
            width: 28,
            maxWidth: 28,
            minWidth: 28,
            p: 0,
            height: 28,
            borderRadius: t.borderRadius.md,
            backgroundColor: 'white',
            border: `1px solid ${t.palette.grey[300]}`,
            '&:hover': {
              border: `1px solid ${t.palette.grey[400]}`,
            },
          },
        };
      }}
    >
      {icon}
    </Button>
  );
};

export const ActionTypeFieldSelect: FC<{
  onChange: (value: ActionFieldsOptions) => void;
  value: string;
}> = ({ onChange, value }) => {
  const typeFields = useRef<{ id: ActionFieldsOptions; label: string }[]>([
    { id: 'metric', label: 'Metric value' },
    { id: 'absolute', label: 'Number value' },
  ]);

  return (
    <Select
      value={value}
      onChange={(e) => {
        onChange(e.target.value as ActionFieldsOptions);
      }}
      sx={(t) => ({
        '&.MuiInputBase-root, &.MuiInputBase-root fieldset': { border: 'none' },
        '&.MuiInputBase-root': {
          minHeight: 24,
          maxHeight: 24,
          mt: 0.25,
        },
        '.MuiSelect-select': {
          minWidth: 90,
        },
      })}
    >
      {typeFields.current.map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const ActionsType: FC<ActionProps> = observer(({ indexAction, indexVariant, indexTask, field }) => {
  const handleChangeMetric = useCallback(
    (value: OptionValue) => {
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexVariant,
        indexAction,
        field,
        value: value as string,
      });
    },
    [indexTask, indexVariant, indexAction, field]
  );

  const { data: actionsType } = useActionsType({
    config: {
      // https://welltech.atlassian.net/wiki/spaces/MA1/pages/4729242064/Rule+form#Actions
      select: (data: ActionsTypeEntry[]) =>
        data
          .filter(({ name }) => !SUB_ACTIONS.includes(name) || REST_ACTIONS.includes(name))
          .map(({ name }) => ({ value: name, label: name })),
    },
  });

  const storedValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })[field];

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue ?? ''}
      onChange={handleChangeMetric}
      id={`Autocomplete-${field}-${indexTask}-${indexAction}`}
      options={actionsType ?? []}
    />
  );
});

export const SubActionsType: FC<Pick<ActionProps, 'indexAction' | 'indexTask' | 'indexVariant'>> = observer(
  ({ indexAction, indexVariant, indexTask }) => {
    const handleChangeMetric = useCallback(
      (value: OptionValue) => {
        tasksFormStore.setSubActionField({
          indexTask,
          indexVariant,
          indexAction,
          field: 'action',
          value: value as string,
        });
      },
      [indexTask, indexVariant, indexAction]
    );

    const storedAction = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant }).action;
    const storedSubAction = tasksFormStore.getSubActionValues({ indexTask, indexAction, indexVariant }).action;

    const { data: actionsType } = useActionsType({
      config: {
        // https://welltech.atlassian.net/wiki/spaces/MA1/pages/4729242064/Rule+form#Actions
        select: (data: ActionsTypeEntry[]) =>
          data
            .filter(({ name }) => SUB_ACTIONS.includes(name) && storedAction !== name) // only sub actions value and exclude sub_budget action in case with a the same sub action value
            .map(({ name }) => ({ value: name, label: name })),
      },
    });

    return (
      <RuleEditAutoCompleteStyled
        allSelectable={false}
        value={storedSubAction ?? ''}
        onChange={handleChangeMetric}
        id={`Autocomplete.Task:${indexTask}.Variant:${indexVariant}.Action:${indexAction}.SubActions:Action`}
        options={actionsType ?? []}
      />
    );
  }
);

export const ActionMetrics: FC<ActionProps> = observer(({ indexAction, indexVariant, indexTask, field }) => {
  const handleChangeMetric = useCallback(
    (value: OptionValue) => {
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field,
        value: value as string,
      });
    },
    [indexTask, indexAction, indexVariant, field]
  );

  const { data: metrics } = useConditionsConstants({
    config: { select: (data) => data.map(({ name, id }) => ({ value: id, label: name })) },
  });

  const storedValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.[field];
  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue as string}
      onChange={handleChangeMetric}
      id={`AutocompleteActionMetrics:${indexTask}.${indexAction}.${field}`}
      options={metrics ?? []}
    />
  );
});

export const SubActionMetrics: FC<Pick<ActionProps, 'indexAction' | 'indexTask' | 'indexVariant'>> = observer(
  ({ indexAction, indexVariant, indexTask }) => {
    const handleChangeMetric = useCallback(
      (value: OptionValue) => {
        tasksFormStore.setSubActionField({
          indexTask,
          indexAction,
          indexVariant,
          field: 'value',
          value: value as string,
        });
      },
      [indexTask, indexAction, indexVariant]
    );

    const { data: metrics } = useConditionsConstants({
      config: { select: (data) => data.map(({ name, id }) => ({ value: id, label: name })) },
    });

    const storedValue = tasksFormStore.getSubActionValues({ indexTask, indexAction, indexVariant }).value;

    return (
      <RuleEditAutoCompleteStyled
        allSelectable={false}
        value={storedValue}
        onChange={handleChangeMetric}
        id={`AutocompleteActionMetrics:${indexTask}.${indexAction}.SubAction:Value`}
        options={metrics ?? []}
      />
    );
  }
);

export const ActionValue: FC<
  ActionProps & { type?: HTMLInputTypeAttribute; disabled?: boolean; placeholder?: string }
> = observer(({ indexAction, indexTask, indexVariant, field, disabled, placeholder, type = 'text' }) => {
  const handleChangeMetric = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field,
        value: e.target.value,
      });
    },
    [indexTask, indexVariant, indexAction, field]
  );

  const storedValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.[field];

  return (
    <TextField
      value={storedValue ?? ''}
      onChange={handleChangeMetric}
      sx={{
        '&.MuiTextField-root': { width: '100%' },
        '& .MuiInputBase-input': { borderRadius: 0 },
        '& .MuiOutlinedInput-root': { maxHeight: 36, minHeight: 36, borderRadius: 0, px: 2 },
        '& fieldset': { border: 'none', top: 0 },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
      }}
      InputProps={{ inputProps: { min: 0, style: { padding: 0, textAlign: 'left' } } }}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
});

type SubActionValueProps = Pick<ActionProps, 'indexAction' | 'indexTask' | 'indexVariant'> & {
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  placeholder?: string;
};

export const SubActionValue: FC<SubActionValueProps> = observer(
  ({ indexAction, indexTask, indexVariant, disabled, placeholder, type = 'text' }) => {
    const handleChangeMetric = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        tasksFormStore.setSubActionField({
          indexTask,
          indexAction,
          indexVariant,
          field: 'value',
          value: e.target.value,
        });
      },
      [indexTask, indexVariant, indexAction]
    );

    const storedValue = tasksFormStore.getSubActionValues({ indexTask, indexAction, indexVariant }).value;

    return (
      <TextField
        value={storedValue ?? ''}
        onChange={handleChangeMetric}
        sx={{
          '&.MuiTextField-root': { width: '100%' },
          '& .MuiInputBase-input': { borderRadius: 0 },
          '& .MuiOutlinedInput-root': { maxHeight: 36, minHeight: 36, borderRadius: 0, px: 2 },
          '& fieldset': { border: 'none', top: 0 },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}
        InputProps={{ inputProps: { min: 0, style: { padding: 0, textAlign: 'left' } } }}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }
);

export const ActionUnitValue: FC<ActionIndexProps> = observer(({ indexTask, indexVariant, indexAction }) => {
  const { isUnitVisible, isSubActionVisible } = actionFieldsVisibility({ indexAction, indexTask });
  const storedUnitValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.unit;
  const handleActionUnit = useCallback(() => {
    const newUnitValue = storedUnitValue === 'absolute' ? 'percent' : 'absolute';
    tasksFormStore.setActionFieldValue({
      indexTask,
      indexAction,
      indexVariant,
      field: 'unit',
      value: newUnitValue,
    });
  }, [tasksFormStore, storedUnitValue, indexTask, indexVariant, indexAction]);

  return (
    isUnitVisible && (
      <Box
        sx={{
          flex: '0 0 42px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: isSubActionVisible ? `1px solid ${`rgba(0, 8, 46, 0.12)`}` : 'none',
        }}
      >
        <Tooltip title={storedUnitValue === 'percent' ? 'Percent value' : 'Number value'} placement="top">
          <Box>
            <ActionToggleButton
              onClick={handleActionUnit}
              isActive={true}
              icon={
                storedUnitValue === 'percent' ? (
                  <Percent
                    sx={(t) => ({
                      fontSize: t.typography.h3.fontSize,
                      color: t.palette.text.secondary,
                    })}
                  />
                ) : (
                  <TagIcon
                    sx={(t) => ({
                      fontSize: t.typography.h3.fontSize,
                      color: t.palette.text.secondary,
                    })}
                  />
                )
              }
            />
          </Box>
        </Tooltip>
      </Box>
    )
  );
});

export const ActionCountValueField: FC<ActionIndexProps> = observer(({ indexAction, indexVariant, indexTask }) => {
  const { isCountVisible } = actionFieldsVisibility({ indexAction, indexTask, indexVariant });
  const storedValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.count;

  const [actionFieldsOptions, setActionFieldsOptions] = useState<ActionFieldsOptions>(() =>
    typeof storedValue === 'string' && isNaN(Number(storedValue)) ? 'metric' : 'absolute'
  );

  const handleActionTypeField = useCallback(
    (value: ActionFieldsOptions) => {
      setActionFieldsOptions(value);
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field: 'count',
        value: '',
      });
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field: 'unit',
        value: 'absolute',
      });
    },
    [setActionFieldsOptions, actionFieldsOptions, indexVariant, indexTask, indexAction, tasksFormStore]
  );

  return isCountVisible ? (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', minHeight: 37 }}>
        <Box
          sx={{
            flex: '0 0 40%',
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
            borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
          }}
        >
          <Typography variant="body2" sx={(t) => ({ pl: 2, color: t.palette.text.secondary })}>
            action count:
          </Typography>
        </Box>
        <Box
          sx={{
            flex: '0 0 90px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
            borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
          }}
        >
          <ActionTypeFieldSelect onChange={handleActionTypeField} value={actionFieldsOptions} />
        </Box>
        <Box sx={{ flex: 1, borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}` }}>
          {actionFieldsOptions === 'metric' ? (
            <Suspense
              fallback={
                <Box sx={{ p: 0.5, display: 'flex', width: '100%', maxHeight: 36 }}>
                  <Skeleton
                    width={'100%'}
                    height={26}
                    sx={{ transform: 'translateY(0)', borderRadius: '4px', m: 0, p: 0 }}
                  />
                </Box>
              }
            >
              <ActionMetrics
                indexAction={indexAction}
                indexTask={indexTask}
                indexVariant={indexVariant}
                field="count"
              />
            </Suspense>
          ) : (
            <ActionValue
              indexAction={indexAction}
              indexTask={indexTask}
              indexVariant={indexVariant}
              field="count"
              type="number"
            />
          )}
        </Box>
      </Box>
    </>
  ) : null;
});

export const SubActionValueField: FC<ActionIndexProps> = observer(({ indexAction, indexVariant, indexTask }) => {
  const { isSubActionVisible } = actionFieldsVisibility({ indexAction, indexTask, indexVariant });
  const storedSubValue = tasksFormStore.getSubActionValues({ indexTask, indexAction, indexVariant }).value;

  const [actionFieldsOptions, setActionFieldsOptions] = useState<ActionFieldsOptions>(() =>
    typeof storedSubValue === 'string' && isNaN(Number(storedSubValue)) ? 'metric' : 'absolute'
  );

  const handleActionTypeField = useCallback(
    (value: ActionFieldsOptions) => {
      setActionFieldsOptions(value);
    },
    [setActionFieldsOptions]
  );

  return isSubActionVisible ? (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          sx={{
            flex: '0 0 40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexWrap: 'nowrap',
            pl: 1.5,
            borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
          }}
        >
          <SubdirectoryArrowRight color="primary" />
          <SubActionsType indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
        </Box>
        <Box
          sx={{
            flex: '0 0 42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
          }}
        >
          <ActionTypeFieldSelect onChange={handleActionTypeField} value={actionFieldsOptions} />
        </Box>
        <Box sx={{ flex: 1 }}>
          {actionFieldsOptions === 'metric' ? (
            <Suspense
              fallback={
                <Box sx={{ p: 0.5, display: 'flex', width: '100%', maxHeight: 36 }}>
                  <Skeleton
                    width={'100%'}
                    height={26}
                    sx={{ transform: 'translateY(0)', borderRadius: '4px', m: 0, p: 0 }}
                  />
                </Box>
              }
            >
              <SubActionMetrics indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
            </Suspense>
          ) : (
            <SubActionValue indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} type="text" />
          )}
        </Box>
      </Box>
    </>
  ) : null;
});

export const ActionReplaceNameFields: FC<ActionIndexProps> = ({ indexAction, indexVariant, indexTask }) => {
  const { isReplaceInName } = actionFieldsVisibility({ indexAction, indexVariant, indexTask });
  return (
    isReplaceInName && (
      <>
        <Box
          sx={{
            flex: 1,
            borderLeft: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
            borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
          }}
        >
          <ActionValue
            indexAction={indexAction}
            indexVariant={indexVariant}
            indexTask={indexTask}
            field="value"
            type="text"
            placeholder="Enter value"
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ActionValue
            indexAction={indexAction}
            indexTask={indexTask}
            indexVariant={indexVariant}
            field="second_value"
            type="text"
            placeholder="Enter second value"
          />
        </Box>
      </>
    )
  );
};

interface NotificationChannelsAutocompleteInputProps {
  label?: string;
  inputLabel?: string;
  name: string;
  required?: boolean;
}

export const NotificationChannelsAutocomplete: FC<NotificationChannelsAutocompleteInputProps> = ({
  name,
  label,
  inputLabel,
  required,
}) => {
  const { data: notificationChannels } = useNotificationChannels<Option[]>({
    config: {
      select: (data): Option[] => {
        return data.map(({ id, name }) => ({ value: id, label: name }));
      },
    },
  });

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {label && !inputLabel && (
        <Typography variant="subtitle2" mb={1}>
          {label}
          {required && '*'}
        </Typography>
      )}
      <FormControl sx={{ flex: 1 }}>
        <Autocomplete
          InputProps={{ disabled: isEmpty(notificationChannels), label: inputLabel || '' }}
          options={notificationChannels ?? []}
          allSelectable={false}
          name={name}
          id={name}
          label={inputLabel}
          innerLabel
        />
      </FormControl>
    </Box>
  );
};
