import type { FC } from 'react';
import { useCallback } from 'react';
import { Typography } from '@mui/material';
import type { ConfirmDialogProps } from '../types';
import useChosenRules from '../hooks/useChosenRules';
import useListParams from '../../../hooks/useListParams';
import { useProjects } from '../../projects';
import ConfirmDialog from '../../../components/common/ConfirmDialog';

const ConfirmRunManuallyDialog: FC<ConfirmDialogProps> = ({ errorMessage, isOpen, isPending, close, onConfirm }) => {
  const { rulesIds, isIndeterminate } = useChosenRules();
  const { project } = useListParams();
  const { data: listProjects } = useProjects<Record<string, string>>({
    config: {
      select: (data) => data.reduce((accum, { name, id }) => ({ ...accum, [id]: name }), {}),
    },
  });

  const generateTitle = useCallback(() => {
    const projectTitle = project === `all_projects` ? `in all projects` : `in ${listProjects[project]} project ?`;
    return !isIndeterminate
      ? `Run all rules in ${projectTitle}`
      : rulesIds.length === 1
        ? `Run rule manually ${projectTitle}`
        : `Run ${rulesIds.length} rules manually ${projectTitle}`;
  }, [listProjects, project, isIndeterminate, rulesIds]);

  return (
    <ConfirmDialog
      title={generateTitle()}
      text={
        !!errorMessage && (
          <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
            {errorMessage}
          </Typography>
        )
      }
      confirmButton={`Run ${rulesIds.length === 1 ? `rule` : `rules`} manually`}
      cancelButton="Cancel"
      isOpen={isOpen}
      isPending={isPending}
      close={close}
      onCancel={close}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmRunManuallyDialog;
