import { useCurrentNetwork } from 'hooks';
import { useEffect, type FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Network } from 'types/shared';

export const ALLOWED_NETWORKS: Network[] = ['facebook', 'google'];

interface Props {
  children: React.ReactNode;
}
const NetworkProvider: FC<Props> = ({ children }) => {
  const network = useCurrentNetwork();
  const isNetworkInvalid = !!network && !ALLOWED_NETWORKS.includes(network);

  useEffect(() => {
    if (isNetworkInvalid) {
      localStorage.removeItem('_network');
    } else {
      localStorage.setItem('_network', String(network));
    }
  }, [network, isNetworkInvalid]);

  if (isNetworkInvalid) {
    return <Navigate to="/networks" replace />;
  }

  return children;
};

export default NetworkProvider;
