import { Suspense, type FC } from 'react';
import { Titled } from 'react-titled';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { RuleDetails } from '../../features/rules/facebook';

const RulesPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `Wellpilot | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>
        <RuleDetails />
      </Suspense>
    </>
  );
};

export default RulesPage;
