import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import timeTableStore from '../stores/timeTableStore';
import TimeRow from './TimeRow';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LabelFormSection } from '../../components/RuleEditComponents';
import type { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { WEEK_DAY_VALUES } from '../constants';
import UncontrolledChipInput from 'components/common/UncontrolledChipInput';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCallback } from 'react';

interface TimeTableContainerProps {
  scheduledTimezone: string;
}

const TimeTableContainer: FC<TimeTableContainerProps> = observer(({ scheduledTimezone }) => {
  const handleAddTime = useCallback(() => {
    timeTableStore.addTime();
  }, []);

  const handleChangeHours = useCallback((value: number[]) => {
    timeTableStore.setScheduleHours(value);
  }, []);

  const handleChangeMinutes = useCallback((value: number[]) => {
    timeTableStore.setScheduleMinutes(value);
  }, []);

  if (timeTableStore.scheduleInterval) {
    return (
      <Box sx={{ width: '60%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ mr: 3, fontWeight: 500 }}>
            Timetable interval
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mr: 3, width: 50 }}>
            Hours:
          </Typography>
          <UncontrolledChipInput
            helperText="If no hours selected, rule will run every hour during 24h period."
            name="hours"
            value={timeTableStore.intervalDTO.hours}
            onChange={handleChangeHours}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mr: 3, width: 50 }}>
            Minutes:
          </Typography>
          <UncontrolledChipInput
            helperText="If no minutes selected, rule will run in the 1st minute of selected hours."
            name="minutes"
            value={timeTableStore.intervalDTO.minutes}
            onChange={handleChangeMinutes}
          />
        </Box>
        <Divider sx={{ mb: 1.5 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlinedIcon sx={(t) => ({ color: t.palette.primary.main, fontSize: 14, mr: 1 })} />
          <Typography variant="caption">
            <b>For example:</b> hours 1, 3 and minutes 0, 30 will run the rule at 1:00, 1:30, 3:00, 3:30
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" sx={{ mr: 3, fontWeight: 500 }}>
          Timetable
        </Typography>
        <TextField value={scheduledTimezone} disabled label="Scheduled timezone" />
      </Box>
      <Box
        sx={{
          border: `1px solid rgba(0, 8, 46, 0.12)`,
          p: 2,
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '60%' }}>
          <Box sx={{ mb: 2 }}>
            <LabelFormSection label="Timetable" icon={<CalendarTodayIcon />} />
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ width: '100px', mr: 2 }}>Time</Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
              {WEEK_DAY_VALUES.map((wd) => (
                <Box key={wd} sx={{ width: '50px', textAlign: 'center', textTransform: 'capitalize' }}>
                  {wd.slice(0, 3)}
                </Box>
              ))}
            </Box>
            <Box sx={{ width: '10%' }}></Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {[...timeTableStore.reversedTimetable.entries()].map(([key, value], index) => (
              <TimeRow key={key} id={key} time={value.time} index={index} />
            ))}
          </Box>
          <Box sx={{ pt: 2.5 }}>
            <Button onClick={handleAddTime}>
              <AddIcon sx={{ mr: 1 }} /> ADD RUN TIME
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box>
            <Button
              disabled={timeTableStore.isSorted}
              onClick={() => {
                timeTableStore.sort();
              }}
              sx={(t) => ({ color: t.palette.text.primary })}
            >
              <ArrowDownwardIcon sx={{ mr: 1, fontSize: 20 }} />
              Sort
            </Button>
          </Box>
          <Box>
            <Button
              sx={(t) => ({ color: t.palette.text.primary })}
              onClick={() => {
                timeTableStore.clear();
              }}
            >
              <CloseIcon sx={{ mr: 1, fontSize: 20 }} />
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default TimeTableContainer;
