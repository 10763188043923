import { Box } from '@mui/material';
import FilterSearchField from 'components/common/FilterSearchField';
import useListParams from 'hooks/useListParams';

const Filters = () => {
  const { setQ } = useListParams();

  return (
    <Box
      sx={{
        pt: 2,
        pr: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        flexGrow: 1,
        gap: 1,
        maxHeight: 70,
        overflow: 'hidden',
        overflowX: 'auto',
      }}
    >
      <FilterSearchField
        containerSx={{ flex: 1, maxWidth: 260 }}
        setParam={setQ}
        name="q"
        placeholder="Search by name"
      />
    </Box>
  );
};

export default Filters;
