import { makeAutoObservable } from 'mobx';
import { cloneDeep, isEmpty } from 'lodash-es';
import type { RuleFilter } from '../../types';

class FilterStore {
  filterGroups: RuleFilter[][] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setFilterGroups(filterGroups: RuleFilter[][]) {
    this.filterGroups = filterGroups;
  }

  reset() {
    this.filterGroups = [];
  }

  updateRuleFilterField(groupIndex: number, filterIndex: number, newValue: string) {
    const filter = this.filterGroups[groupIndex][filterIndex];

    if (filter.field.includes('effective_status') && !newValue.includes('effective_status')) {
      this.updateRuleFilterValue(groupIndex, filterIndex, '');
    }

    if (newValue === 'flow') {
      this.updateRuleFilterOperator(groupIndex, filterIndex, 'CONTAIN');
    }

    filter.field = newValue;
  }

  updateRuleFilterOperator(groupIndex: number, filterIndex: number, newValue: string) {
    this.filterGroups[groupIndex][filterIndex].operator = newValue;
  }

  updateRuleFilterValue(groupIndex: number, filterIndex: number, newValue: string | string[]) {
    this.filterGroups[groupIndex][filterIndex].value = newValue;
  }

  getRuleFilter(groupIndex: number, filterIndex: number): RuleFilter | null {
    if (isEmpty(this.filterGroups)) return null;
    return this.filterGroups[groupIndex][filterIndex];
  }

  addFilter(groupIndex: number) {
    const isLastFilterValid = this.validateLastFilter(groupIndex);
    if (isLastFilterValid) {
      this.filterGroups[groupIndex].push({ field: '', operator: '', value: '' });
    }
    return isLastFilterValid;
  }

  removeFilterGroup(groupIndex: number): boolean {
    if (this.filterGroups.length > 1) {
      this.filterGroups = this.filterGroups.filter((fg, i) => i !== groupIndex);
      return true;
    }
    return false;
  }

  removeFilter(groupIndex: number, index: number): boolean {
    const newFilters = this.filterGroups[groupIndex].filter((fg, i) => i !== index);
    if (newFilters.length) {
      this.filterGroups[groupIndex] = this.filterGroups[groupIndex].filter((fg, i) => i !== index);
      return true;
    } else {
      return this.removeFilterGroup(groupIndex);
    }
  }

  addFilterGroup() {
    this.filterGroups.push([{ field: '', operator: '', value: '' }]);
  }

  validateLastFilter(groupIndex: number) {
    const group = this.filterGroups[groupIndex];
    return Object.values(group[group.length - 1]).every((v) => !!v);
  }

  duplicateFilterGroup(groupIndex: number) {
    this.filterGroups.push(cloneDeep(this.filterGroups[groupIndex]));
  }
}

const filterStore = new FilterStore();

export default filterStore;
