import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { getGoogleRuleDetailsOptions } from './getGoogleRuleDetails';
import type { UpdateRulePayload } from './types';
import type { RuleMutationResponse } from '../types';
import type { RuleMutationErrorResponse } from '../types';
import { GOOGLE_RULES_LIST_KEY } from './getRulesList';

export const updateRule = async ({ id, ...body }: UpdateRulePayload): Promise<RuleMutationResponse> => {
  const res = await apiClient.put<{ data: RuleMutationResponse }>(`ga/rules/${id}`, body);
  if (isAxiosError(res)) {
    const error = new Error('Update rule error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data.data;
};

interface UseUpdateRuleOptions {
  config?: MutationConfig<typeof updateRule>;
}

export const useUpdateGoogleRule = ({ config }: UseUpdateRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onShowErrorAlert } = useEnqueueSnackbar({ delay: 6000 });

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getGoogleRuleDetailsOptions(args[1].id).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: [GOOGLE_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: (error) => {
      const cause = error.cause as unknown as RuleMutationErrorResponse;
      cause.message && onShowErrorAlert(cause.message);

      const message = error?.message;
      if (message) {
        onShowErrorAlert(message);
        return;
      }

      const [{ input: invalidValue, loc, msg }] = cause.errors;
      onShowErrorAlert(`Invalid value ${invalidValue} for field ${loc[loc.length - 1]}: ${msg}`);
    },
    mutationFn: updateRule,
  });
};
