import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { RuleMutationErrorResponse, RuleMutationResponse } from '../types';
import { isAxiosError } from 'axios';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { CreateRulePayload } from './types';
import { GOOGLE_RULES_LIST_KEY } from './getRulesList';

export const createGoogleRule = async (body: CreateRulePayload): Promise<RuleMutationResponse> => {
  const res = await apiClient.post<{ data: RuleMutationResponse }>(`ga/rules`, body);
  if (isAxiosError(res)) {
    const error = new Error('Duplicate rule error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data.data;
};

interface UseUpdateRuleOptions {
  config?: MutationConfig<typeof createGoogleRule>;
}

export const useCreateGoogleRule = ({ config }: UseUpdateRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onShowErrorAlert } = useEnqueueSnackbar({ delay: 6000 });

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GOOGLE_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },

    onError: (error) => {
      const cause = error.cause as unknown as RuleMutationErrorResponse;
      cause.message && onShowErrorAlert(cause.message);

      const [{ input: invalidValue, loc, msg }] = cause.errors;
      onShowErrorAlert(`Invalid value ${invalidValue} for field ${loc[loc.length - 1]}: ${msg}`);
    },
    mutationFn: createGoogleRule,
  });
};
