export const WEEK_DAY_VALUES = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const SUB_ACTIONS = ['set_adset_budget', 'set_budget', 'set_parent_adset_budget'];

export const SINGLE_ACTIONS = ['pause', 'start', 'notify', 'remove_datetime_from_name', 'ad_winner'];
export const DUPLICATE_ACTIONS = ['duplicate_and_add_to_name', 'duplicate_parent_adset'];
export const REST_ACTIONS = [
  'set_budget',
  'increase_budget',
  'decrease_budget',
  'increase_bid',
  'decrease_bid',
  'increase_roas',
  'decrease_roas',
  'increase_adset_budget',
  'increase_adset_budget',
  'decrease_adset_budget',
  'q',
];
export const REPLACE_ACTIONS = ['replace_in_name', 'join_days_in_name'];
export const STRING_ACTIONS = ['add_to_name', 'remove_from_name'];
export const FLOW_CONSTANT_METRICS = ['flow', 'constant'];

export const MULTIPLE_FILTER_OPERATORS = ['IN', 'NOT_IN'];
