import type { SxProps, Theme } from '@mui/material';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useCallback } from 'react';
import useListParams from 'hooks/useListParams';
import SwitchInput from 'components/common/SwitchInput';
import { useFormContext } from 'react-hook-form';
import type { UseModalResult } from 'hooks';
import { useModal } from 'hooks';
import useBulkPause from '../../hooks/useBulkPause';
import { useAccessModify } from '../../hooks';
import { Close as CloseIcon } from '@mui/icons-material';
import BulkDuplicateForm from './BulkDuplicateForm';
import RadioGroup from 'components/common/RadioGroup';
import BulkEditForm from './BulkEditForm';
import { ConfirmBulkPauseDialog } from '../../components';

export const ConfirmBulkDuplicateDialog: FC<Pick<UseModalResult, 'close' | 'isOpen'>> = ({ isOpen, close }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      sx={{ p: 0 }}
      PaperProps={{ sx: { pt: 2, pl: 10, pr: 2, boxShadow: 'none' } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          pb: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h3" sx={{ p: 0, mb: 1 }}>
            Duplicate rules
          </DialogTitle>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            There cannot be two rules with the same name and conditions. Make changes to save new rules.
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component={Box} sx={{ ml: '-80px', mr: '-16px' }} />
      <DialogContent sx={{ p: 0 }}>
        <BulkDuplicateForm close={close} />
      </DialogContent>
    </Dialog>
  );
};

export const ConfirmBulkEditDialog: FC<Pick<UseModalResult, 'close' | 'isOpen'>> = ({ isOpen, close }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      sx={{ p: 0 }}
      PaperProps={{ sx: { pt: 2, pl: 10, pr: 2, boxShadow: 'none' } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          pb: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h3" sx={{ p: 0, mb: 1 }}>
            Edit rules
          </DialogTitle>
        </Box>
        <Box>
          <IconButton
            onClick={close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component={Box} sx={{ ml: '-80px', mr: '-16px' }} />
      <DialogContent sx={{ p: 0 }}>
        <BulkEditForm close={close} />
      </DialogContent>
    </Dialog>
  );
};

const SwitchChosenRules = () => {
  const { reset } = useFormContext();
  const { open, close, isOpen } = useModal();

  const handleClose = useCallback(() => {
    close();
    reset();
  }, [close, reset]);
  const { onPause, isPending, isSuccess, isChosenRules } = useBulkPause({ network: 'facebook', onError: handleClose });

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      <Box sx={{ width: 38, height: 26, alignItems: 'center', display: 'flex', position: 'relative' }}>
        <SwitchInput
          handleChange={open}
          name="all_rules_switched"
          size="small"
          disabled={!isChosenRules || isPending}
          sx={{ mb: 0, m: 0 }}
        />
      </Box>
      <ConfirmBulkPauseDialog isOpen={isOpen} isPending={isPending} close={handleClose} onConfirm={onPause} />
    </Box>
  );
};

export const SwitchChosenRulesVisibility = () => {
  const { accessRules } = useAccessModify();
  const { tab } = useListParams();
  const isVisible = accessRules.bulk && tab && ['active', 'paused'].includes(tab);
  return isVisible ? <SwitchChosenRules /> : null;
};

export const RuleStatusParameters: FC<{ name?: string; sx?: SxProps<Theme> }> = ({ name, sx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: 4, ...sx }}>
      <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
        Status
      </Typography>
      <RadioGroup
        row
        name={name ?? 'ruleStatus'}
        options={[
          { value: 'active', label: 'Active' },
          { value: 'paused', label: 'Paused' },
          { value: 'archived', label: 'Archived' },
        ]}
      />
    </Box>
  );
};
