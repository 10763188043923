import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import TitlePageStyles from 'components/common/TitlePageStyles';
import { FilterProjects, FilterProjectsSkeleton } from 'features/projects';
import type { FC} from 'react';
import { Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { filterSchema } from '../validation/filter.schema';
import useListParams from 'hooks/useListParams';
import RulesSidePanel from 'components/common/RulesSidePanel';
import RulesStatusTabsSkeleton from 'components/common/RulesStatusTabsSkeleton';
import RulesStatusTabs from 'components/common/RulesStatusTabs';
import RulesTable from './RulesTable';
import RulesTableSkeleton from './RulesTableSkeleton';
import Filters from './Filters';
import FiltersChannels from './FiltersChannels';
import FiltersChannelsSkeleton from './FiltersChannelsSkeleton';

interface FilterFormData {
  project?: string;
  q?: string;
}

const Rules: FC = () => {
  const { q, project } = useListParams();

  const formMethods = useForm<FilterFormData>({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      project: project ?? 'all_projects',
      q: q ?? '',
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ px: 3, mb: 2 }}>
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'end', mb: 1 }}>
          <TitlePageStyles>Rules</TitlePageStyles>
          <Box>
            <Suspense fallback={<FilterProjectsSkeleton />}>
              <FilterProjects />
            </Suspense>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'start', flexDirection: 'column' }}>
          <Suspense fallback={<RulesStatusTabsSkeleton />}>
            <RulesStatusTabs />
          </Suspense>
          <Filters />
        </Box>
      </Box>

      <Grid container flex={1} sx={{ px: 3 }}>
        <RulesSidePanel title="Channels">
          <Suspense fallback={<FiltersChannelsSkeleton />}>
            <FiltersChannels />
          </Suspense>
        </RulesSidePanel>
        <Grid
          item
          container
          sx={(t) => ({
            flex: 1,
            borderTop: `1px solid ${t.palette.grey[300]}`,
            borderLeft: `1px solid ${t.palette.grey[300]}`,
          })}
        >
          <Suspense fallback={<RulesTableSkeleton />}>
            <RulesTable />
          </Suspense>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default Rules;
