/* eslint-disable */
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import Input from 'components/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formSchema from '../validation/form.schema';
import { useAuthAutoTest } from '../api/auth';

interface FormData {
  email: string;
  password: string;
}

const LoginForm = () => {
  const formMethod = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(formSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethod;

  const auth = useAuthAutoTest({
    config: {
      onSuccess: (data) => {
        Object.entries(data).map((i) => {
          localStorage.setItem(`${[i[0]]}`, `${[i[1]]}`);
        });

        location.href = '/networks';
      },
      onError: (err) => {
        console.error(err);
        reset();
      },
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      localStorage.clear();
      await auth.mutate({ email: data.email, password: data.password });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, maxWidth: 280, width: '90%' }}>
        <Typography variant="h2">Log in to Autorules</Typography>
        {auth.isError && (
          <Typography variant="body2" color="red">
            {auth.error.message || auth.error.cause?.message}
          </Typography>
        )}
        <FormProvider {...formMethod}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flex: 1, width: '100%' }}>
            <Input name="email" size="small" placeholder="Email" type="text" id="autotest-login-email-input" />
            <Input
              name="password"
              size="small"
              placeholder="Password"
              type="password"
              id="autotest-login-password-input"
            />
            <Button
              size="medium"
              disabled={!isValid || auth.isPending}
              startIcon={auth.isPending && <CircularProgress size={18} />}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              id="autotest-login-submit"
            >
              Login
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default LoginForm;
