import * as yup from 'yup';

const filterParamsSchema = yup.object().shape({
  project: yup.string(),
  q: yup.string(),
  weekday: yup.array(),
  today: yup.bool(),
  tomorrow: yup.bool(),
  timeTo: yup.string(),
  timeFrom: yup.string(),
  archiveDate: yup.string(),
  pauseDate: yup.string(),
  action: yup.array(),
  level: yup.array(),
  statusState: yup.array(),
  team: yup.array(),
  ruleType: yup.string(),
  platform: yup.number(),
  listId: yup.array(),
  groups: yup.array(),
});

export default filterParamsSchema;
