import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { RuleEntry } from '../types';
import type { RuleError } from '../types';
import { isAxiosError } from 'axios';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { FB_RULES_LIST_KEY } from './getRulesList';

interface CreateRuleSuccessResponse {
  data: unknown;
}

interface CreateRuleErrorResponse {
  errors: RuleError[];
  message: string;
}

interface CreateRulePayload
  extends Pick<
    RuleEntry,
    'name' | 'description' | 'status' | 'level' | 'network_id' | 'project_id' | 'data' | 'team_id'
  > {
  action_types_ids: number[];
  group_id?: number;
}

type CreateRuleResponse = CreateRuleSuccessResponse | CreateRuleErrorResponse;

export const createRule = async (body: CreateRulePayload): Promise<CreateRuleResponse> => {
  const res = await apiClient.post<{ data: CreateRuleResponse }>(`/rules`, body);
  if (isAxiosError(res)) {
    const error = new Error('Duplicate rule error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseUpdateRuleOptions {
  config?: MutationConfig<typeof createRule>;
}

export const useCreateRule = ({ config }: UseUpdateRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onShowErrorAlert } = useEnqueueSnackbar({ delay: 6000 });

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [FB_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },

    onError: (error) => {
      const cause = error.cause as unknown as CreateRuleErrorResponse;
      cause.message && onShowErrorAlert(cause.message);

      const [{ input: invalidValue, loc, msg }] = cause.errors;
      onShowErrorAlert(`Invalid value ${invalidValue} for field ${loc[loc.length - 1]}: ${msg}`);
    },
    mutationFn: createRule,
  });
};
