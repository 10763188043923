import { Suspense, type FC } from 'react';
import { Titled } from 'react-titled';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { RuleEdit } from '../../features/rules/facebook';
import { EditGoogleRule } from '../../features/rules/google';
import { Box } from '@mui/material';
import { useCurrentNetwork } from '../../hooks';

const RuleEditPage: FC = () => {
  const network = useCurrentNetwork();

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>
        {network === 'facebook' && <RuleEdit mode="edit" />}
        {network === 'google' && <EditGoogleRule mode="edit" />}
      </Suspense>
    </Box>
  );
};

export default RuleEditPage;
