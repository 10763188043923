import { Box, Link, styled, Typography } from '@mui/material';
import FancyDrink from '../../../assets/fancy-drink.webp';
import { useCallback, type FC } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

export const BackgroundBox = styled(Box)`
  padding: 16px 24px;
  background: url(${FancyDrink}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 8px;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    justify-content: space-between;
    max-width: 1200px;
    flex: 2;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`;

export const TitleStyled = styled(Typography)`
  color: #fff;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    width: min-content;
    font-size: 64px;
    line-height: 1.25;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    width: 100%;
    font-size: 42px;
    line-height: 1;
  }
`;

interface NetworkItemProps {
  title: string;
  link: string;
}

export const NetworkItem: FC<NetworkItemProps> = ({ title, link }) => {
  const setCurrentNetwork = useCallback((value: string) => {
    localStorage.setItem('_network', value);
  }, []);

  return (
    <Link
      sx={{
        padding: 6,
        display: 'flex',
        justifyContent: 'space-between',
        background: 'white',
        borderRadius: 3,
        textDecoration: 'none',
      }}
      color="text.primary"
      href={link}
      onClick={() => setCurrentNetwork(link.split('/')[1])}
    >
      <Typography variant="h3">{title}</Typography>
      <ArrowForwardOutlinedIcon sx={{ fontSize: 36 }} color="primary" />
    </Link>
  );
};
