import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { ChannelEntry } from '../types';
import queryKeys from './queryKeys';
import type {GetChannelsParams} from './types';
import { omit } from 'lodash-es';

interface ChannelsPromise {
  all: number;
  channels: ChannelEntry[];
}

const getChannels = async <TData>(params: GetChannelsParams, signal?: AbortSignal): Promise<TData> => {
  const res = await apiClient.get<TData>(`/ga/channels`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseChannels<TData> {
  config?: QueryConfig<typeof getChannels, TData>;
  params: GetChannelsParams;
}

export const useChannels = <TData = ChannelsPromise>({ config, params }: UseChannels<TData>) => {
  const omittedParams = omit({ ...params }, [
    'channel',
    'limit',
    'offset',
    'list_id',
    'order_by',
    'desc',
    'paginationRelatedParams',
  ]);
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getChannels<TData>(omittedParams, signal),
    queryKey: queryKeys.channels(omittedParams),
  });
};
