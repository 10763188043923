import { queryOptions } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { Levels, LevelsParam } from 'types/shared';

export interface LevelsEntry {
  id: LevelsParam;
  name: Levels;
}

export interface SettingsEntry {
  id: string;
  name: string;
}

interface References {
  levels: LevelsEntry[];
  notification_levels: SettingsEntry[];
  filters: {
    operators: SettingsEntry[];
    fields: SettingsEntry[];
  };
  conditions: {
    operators: SettingsEntry[];
    periods: SettingsEntry[];
    metrics: SettingsEntry[];
    levels: SettingsEntry[];
  };
  effective_statuses: {
    ad: SettingsEntry[];
    adset: SettingsEntry[];
    campaign: SettingsEntry[];
  };
}

export const getReferences = async (signal?: AbortSignal): Promise<References> => {
  const res = await apiClient.get<References>(`/references`, {
    signal,
  });
  return res.data;
};

export const getReferencesOptions = queryOptions({
  queryFn: ({ signal }) => getReferences(signal),
  queryKey: ['references'],
  staleTime: 60 * 30 * 1000,
});
