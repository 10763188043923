import { Suspense, type FC } from 'react';
import { Titled } from 'react-titled';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { Rules as RulesFacebook } from 'features/rules/facebook';
import { Rules as RulesGoogle } from 'features/rules/google';
import { useCurrentNetwork } from 'hooks';

const RulesPage: FC = () => {
  const network = useCurrentNetwork();
  const isFacebook = network === 'facebook';

  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>{isFacebook ? <RulesFacebook /> : <RulesGoogle />}</Suspense>
    </>
  );
};

export default RulesPage;
