import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { OptionValue } from 'types/shared';
import filterStore from '../stores/filterStore';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { RuleEditAutocomplete } from './RuleEditAutocomplete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import type { Option } from 'types/shared';
import { useModal } from 'hooks';
import RuleFilterValue from './RuleFilterValue';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MULTIPLE_FILTER_OPERATORS } from '../constants';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { SettingsEntry } from '../api/getRefences';
import { getReferencesOptions } from '../api/getRefences';

interface RuleEditFilterFormProps {
  groupIndex: number;
  index: number;
}

const getOptions = ({ name, id }: SettingsEntry) => ({ value: id, label: name });

const RuleEditFilterForm: FC<RuleEditFilterFormProps> = observer(({ index, groupIndex }) => {
  const { open: setIsHovered, close: setIsNotHovered, isOpen: isHovered } = useModal();
  const { onShowInfoAlert } = useEnqueueSnackbar();

  const { data: filters } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.filters,
  });

  const fields = filters.fields.map(getOptions);
  const operators = filters.operators.map(getOptions);

  const filter = filterStore.getRuleFilter(groupIndex, index);

  const isEffectiveStatus = filter?.field.includes('effective_status');
  const isFlow = filter?.field === 'flow';

  const filteredOperators: Option[] = useMemo(() => {
    if (isFlow) return operators.filter((elem) => elem.value === 'CONTAIN');

    const filterConstraints = MULTIPLE_FILTER_OPERATORS;

    const filterPredicate = (o: Option) => filterConstraints.includes(String(o.value)) === isEffectiveStatus;

    return operators.filter(filterPredicate);
  }, [isEffectiveStatus, operators, isFlow]);

  const handleChangeField = useCallback(
    (value: OptionValue) => {
      filterStore.updateRuleFilterField(groupIndex, index, value as string);
    },
    [groupIndex, index]
  );

  const handleChangeOperator = useCallback(
    (value: OptionValue) => {
      filterStore.updateRuleFilterOperator(groupIndex, index, value as string);
    },
    [groupIndex, index]
  );

  const removeFilter = () => {
    const result = filterStore.removeFilter(groupIndex, index);

    if (!result) {
      onShowInfoAlert(`You can't delete last filter in last filter group`);
    }
  };

  return (
    <Box
      id={`filter-${index}`}
      onMouseEnter={setIsHovered}
      onMouseLeave={setIsNotHovered}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Box
        sx={(t) => ({
          width: 'calc(100% - 50px)',
          border: `1px solid ${t.palette.grey[200]}`,
          borderRadius: t.borderRadius.md,
          transition: '1s ease-in-out',
        })}
      >
        <Box
          sx={(t) => ({
            display: 'flex',
            alignItems: 'center',
            color: t.palette.primary.main,
            backgroundColor: 'rgba(41, 121, 255, 0.04)',
            px: 1,
            py: 0.75,
            fontWeight: 500,
          })}
        >
          <Box sx={{ display: 'flex', mr: 4 }}>
            <FilterListIcon fontSize="large" sx={{ fontSize: 24, mr: 1 }} />{' '}
            <Typography variant="subtitle2">Filter {index + 1}</Typography>
          </Box>
          {(filter?.operator === 'IN' || filter?.operator === 'NOT_IN') && (
            <Box sx={{ display: 'flex' }}>
              <InfoOutlinedIcon fontSize="small" sx={{ fontSize: 16, mr: 0.5 }} />
              <Typography variant="body2" color="text.secondary">
                Values in filter with “IN” operator have “OR” relation
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <RuleEditAutocomplete
              sx={{ height: '100%' }}
              allSelectable={false}
              value={filter?.field}
              InputProps={{
                placeholder: 'Field',
              }}
              onChange={handleChangeField}
              id="field"
              options={fields}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              border: '1px solid rgba(0, 0, 0, 0.15)',
              borderBottomColor: 'transparent',
              borderTopColor: 'transparent',
            }}
          >
            <RuleEditAutocomplete
              allSelectable={false}
              value={filter?.operator}
              InputProps={{
                placeholder: 'Operator',
                disabled: filter?.field === 'flow',
              }}
              onChange={handleChangeOperator}
              id="operator"
              options={filteredOperators}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <RuleFilterValue groupIndex={groupIndex} index={index} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: 50 }}>
        {isHovered && (
          <Tooltip title="Delete filter" placement="top" arrow>
            <IconButton onClick={removeFilter}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
});

export default RuleEditFilterForm;
