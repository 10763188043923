import { Accordion, AccordionDetails, AccordionSummary, Divider, List, ListItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useModal } from 'hooks';
import { usePlatforms } from '../api';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { getPersistedData } from 'utils/persist';
import type { PlatformEntry, PlatformItemEntry } from '../api/types';
import useListParams from 'hooks/useListParams';
import { useFormContext } from 'react-hook-form';
import { useFilterQueryValues } from 'hooks';
import ListItemStyled from 'components/common/ListItemStyled';
import { AddFlow, DeleteFlow, EditFlow } from './FlowsModify';
import FilterPlatformsFlowItem from './FilterPlatformsFlowItem';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import RulesSidePanel from 'components/common/RulesSidePanel';
import type { RulesListParams } from '../types';

const FilterPlatformItem: FC<{ platform: PlatformEntry }> = ({ platform }) => {
  const [deletionFlow, setDeletionFlow] = useState<PlatformItemEntry | null>(null);
  const [editingFlow, setEditingFlow] = useState<PlatformItemEntry | null>(null);
  const { setValue } = useFormContext();
  const { platform: platformParam, listId, setPlatform, setListId } = useListParams();
  const { isOpen: expanded, toggle } = useModal(true);

  const handlePlatformAll = useCallback(
    (platformId: number) => {
      setPlatform(platformId);
      setValue('platform', platformId);

      setValue('listId', []);
      setListId([]);
    },
    [setValue, setListId, setPlatform]
  );

  const toggleDeleteFlow = useCallback(
    (id?: number | string) => {
      setDeletionFlow((prev) => {
        if (!id || prev) return null;
        return platform.list.find((g) => g.id === id) ?? null;
      });
    },
    [setDeletionFlow, platform]
  );

  const toggleEditFlow = useCallback(
    (id?: number | string) => {
      setEditingFlow((prev) => {
        if (!id || prev) return null;
        return platform.list.find((g) => g.id === id) ?? null;
      });
    },
    [setEditingFlow, platform]
  );

  const isAllSelected = platformParam === platform.platform_id && !listId.length;

  return (
    <>
      <ListItem disablePadding>
        <Accordion
          expanded={expanded}
          onChange={toggle}
          sx={{
            '&': {
              p: 0,
              width: '100%',
              minHeight: 32,
              '& .MuiButtonBase-root.MuiAccordionSummary-root': { minHeight: 32, maxHeight: 32, px: 1 },
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
            <Typography
              sx={(t) => ({ fontSize: t.typography.body2.fontSize, p: 0, m: 0, color: t.palette.text.primary })}
            >
              {platform.platform_name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding>
              <ListItemStyled
                key={`Key:${platform.platform_name}`}
                selected={isAllSelected}
                name={'All flows'}
                quantity={platform.all}
                onClick={() => {
                  handlePlatformAll(platform.platform_id);
                }}
                sx={{ '&': { pl: 3 } }}
              />

              {platform.list.map((item) => (
                <FilterPlatformsFlowItem
                  key={`platform:flow:${item.id}.${item.name}.${item.quantity}`}
                  id={item.id}
                  name={item.name}
                  quantity={item.quantity}
                  platformId={platform.platform_id}
                  toggleDelete={toggleDeleteFlow}
                  toggleEdit={toggleEditFlow}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </ListItem>
      <Divider sx={{ my: 2 }} />
      {deletionFlow && (
        <DeleteFlow
          isOpen={!!deletionFlow}
          close={toggleDeleteFlow}
          id={deletionFlow.id}
          name={deletionFlow.name}
          open={toggleDeleteFlow}
        />
      )}
      {editingFlow && (
        <EditFlow id={editingFlow.id} name={editingFlow.name} isOpen={!!editingFlow} close={toggleEditFlow} />
      )}
    </>
  );
};

const FilterPlatforms = () => {
  const { setValue } = useFormContext();
  const { listId, platform, setPlatform, setListId } = useListParams();
  const [, setFlowsOpen] = useQueryParam(
    'flowsOpen',
    withDefault(BooleanParam, !!getPersistedData('directoryFilterVisibility'))
  );

  const params = useFilterQueryValues<RulesListParams>();
  const { data } = usePlatforms({
    params,
  });

  const handleToggle = useCallback(() => {
    setFlowsOpen((prevState) => !prevState);
  }, [setFlowsOpen]);

  const handleAll = useCallback(() => {
    setValue('platform', undefined);
    setValue('listId', []);
    setPlatform(undefined);
    setListId([]);
  }, [setPlatform, setListId, setValue]);

  return (
    <RulesSidePanel title="Directory" onToggle={handleToggle}>
      <List disablePadding>
        <ListItemStyled selected={!listId.length && !platform} name={'All'} quantity={data.all} onClick={handleAll} />
        <Divider sx={{ my: 2 }} />
        <AddFlow />
        {data.platforms.map((platform) =>
          platform.all ? (
            <FilterPlatformItem
              key={`platform:${platform.platform_id}.${platform.platform_name}`}
              platform={platform}
            />
          ) : null
        )}
      </List>
    </RulesSidePanel>
  );
};

export default FilterPlatforms;
