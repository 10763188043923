import type { FC } from 'react';
import { Typography } from '@mui/material';
import { AccessTimeOutlined } from '@mui/icons-material';

const RuleUpdatedMessage: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography
      variant="body2"
      sx={(t) => ({
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderRadius: t.borderRadius.md,
        p: 1.5,
        backgroundColor: 'rgba(236, 239, 241, 1)',
        color: 'rgba(1, 67, 97, 1)',
      })}
    >
      <AccessTimeOutlined />
      {text}
    </Typography>
  );
};

export default RuleUpdatedMessage;
