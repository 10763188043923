import {
  PauseMenuItem,
  RulesTableMenuContainer,
  ArchiveRulesMenuItem,
  UnarchiveRulesMenuItem,
  RunManuallyMenuItem,
} from '../../components';

const RulesTableMenu = () => {
  return (
    <RulesTableMenuContainer>
      {({ closeMenu }) => (
        <>
          <RunManuallyMenuItem network={'google'} closeMenu={closeMenu} />
          <PauseMenuItem network={'google'} closeMenu={closeMenu} />
          <ArchiveRulesMenuItem network={'google'} closeMenu={closeMenu} />
          <UnarchiveRulesMenuItem network={'google'} status={'active'} closeMenu={closeMenu} />
          <UnarchiveRulesMenuItem network={'google'} status={'paused'} closeMenu={closeMenu} />
        </>
      )}
    </RulesTableMenuContainer>
  );
};

export default RulesTableMenu;
