/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { makeAutoObservable } from 'mobx';
import { intervalSchema, timeTableTimeSchema } from '../validation/timeTable.schema';
import * as yup from 'yup';
import type { RuleTimeTable, ScheduleInterval, TimeTableDays } from '../../types';

interface ReversedTimetableValue {
  time: string;
  days: TimeTableDays[];
}

class TimeTableStore {
  reversedTimetable: Map<string, ReversedTimetableValue> = new Map<string, ReversedTimetableValue>();
  isSorted = true;

  scheduleInterval: ScheduleInterval | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setInitialScheduleInterval(interval: ScheduleInterval) {
    this.scheduleInterval = interval;
  }

  setScheduleHours(value: number[]) {
    try {
      if (this.scheduleInterval) {
        this.scheduleInterval.hours = value.map(Number);
      }
    } catch (e) {
      console.error(e);
    }
  }

  setScheduleMinutes(value: number[]) {
    try {
      if (this.scheduleInterval) {
        this.scheduleInterval.minutes = value.map(Number);
      }
    } catch (e) {
      console.error(e);
    }
  }

  get reversedTimetableEntries(): [string, ReversedTimetableValue][] {
    return Array.from(this.reversedTimetable);
  }

  get days(): TimeTableDays[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  }

  reset() {
    this.reversedTimetable = new Map<string, ReversedTimetableValue>();
    this.scheduleInterval = null;
  }

  checkTimeInDay(id: string, day: TimeTableDays) {
    return !!this.reversedTimetable.get(id)?.days.includes(day);
  }

  convertTimeToNumber(time: string) {
    return Number(time.replaceAll(':', ''));
  }

  get isEmpty() {
    return this.reversedTimetableEntries.length === 0;
  }

  sort() {
    const sortedMap = new Map<string, ReversedTimetableValue>();

    this.reversedTimetableEntries
      .sort(([, a], [, b]) => this.convertTimeToNumber(a.time) - this.convertTimeToNumber(b.time))
      .forEach(([k, v]) => {
        sortedMap.set(k, v);
      });

    this.reversedTimetable = sortedMap;
  }

  setInitialData(table: RuleTimeTable) {
    const result: Map<string, ReversedTimetableValue> = new Map<string, ReversedTimetableValue>();
    const groupedByTime: Record<string, TimeTableDays[]> = {};

    Object.entries(table).forEach(([key, value]: [string, string[]]) => {
      value.forEach((time) => {
        const searchedElem = groupedByTime[time];
        if (searchedElem) {
          searchedElem.push(key as TimeTableDays);
        } else {
          groupedByTime[time] = [key as TimeTableDays];
        }
      });
    });

    Object.entries(groupedByTime).forEach(([key, value]: [string, string[]]) => {
      const id = self.crypto.randomUUID();

      result.set(id, {
        time: key.slice(0, 5),
        days: value as TimeTableDays[],
      });
    });

    this.reversedTimetable = result;
  }

  toggleTime(id: string, day: TimeTableDays) {
    const days = this.reversedTimetable.get(id)?.days;

    if (days) {
      const dayIndex = Number(days.indexOf(day));
      if (dayIndex > -1) {
        days.splice(dayIndex, 1);
      } else {
        days.push(day);
      }
    }
  }

  addTime() {
    const valueArray = this.reversedTimetableEntries.map(([, v]) => v);
    const { time, days } = valueArray[valueArray.length - 1];

    this.reversedTimetable.set(self.crypto.randomUUID(), {
      time,
      days: [...days],
    });
  }

  validateTime(time: string): { isValid: boolean; errors: string[] } {
    try {
      timeTableTimeSchema.validateSync({ time });
      return { isValid: true, errors: [] };
    } catch (error) {
      if (error instanceof yup.ValidationError) return { isValid: false, errors: error.errors };
      return { isValid: false, errors: [] };
    }
  }

  validateInterval(interval: ScheduleInterval): { isValid: boolean; errors: string[] } {
    try {
      intervalSchema.validateSync(interval);
      return { isValid: true, errors: [] };
    } catch (error) {
      if (error instanceof yup.ValidationError) return { isValid: false, errors: error.errors };
      return { isValid: false, errors: [] };
    }
  }

  clear() {
    const [key, value] = this.reversedTimetableEntries[1];
    const newMap = new Map<string, ReversedTimetableValue>();
    newMap.set(key, value);

    this.reversedTimetable = newMap;
  }

  deleteTime(id: string) {
    if (Array.from(this.reversedTimetable).length < 2) {
      return false;
    }
    this.reversedTimetable.delete(id);
    return true;
  }

  updateTime(id: string, newTime: string, cb: (errors: string[]) => void) {
    const { isValid, errors } = this.validateTime(newTime);
    if (!isValid) {
      cb(errors);
      return false;
    }
    const idArray = Array.from(this.reversedTimetable.keys());
    const currentIdIndex = idArray.indexOf(id);
    const currentValue = this.convertTimeToNumber(newTime);
    const prevValue =
      currentIdIndex !== 0
        ? this.convertTimeToNumber(String(this.reversedTimetable.get(idArray[currentIdIndex - 1])?.time))
        : -1;
    const nextValue =
      currentIdIndex !== idArray.length - 1
        ? this.convertTimeToNumber(String(this.reversedTimetable.get(idArray[currentIdIndex + 1])?.time))
        : -1;

    if (prevValue > -1 && prevValue > currentValue) {
      this.isSorted = false;
    }
    if (nextValue > -1 && nextValue < currentValue) {
      this.isSorted = false;
    }
    this.reversedTimetable.set(id, {
      days: this.reversedTimetable.get(id)!.days,
      time: newTime,
    });
    return true;
  }

  get timeTableDTO() {
    const result: Record<TimeTableDays, string[]> = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };

    this.reversedTimetable.forEach((value) => {
      value.days.forEach((d) => result[d].push(value.time + ':00'));
    });

    return result;
  }

  get intervalDTO() {
    if (!this.scheduleInterval) {
      return { hours: [], minutes: [] };
    }
    const { hours, minutes } = { ...this.scheduleInterval };
    return { hours: [...hours], minutes: [...minutes] };
  }
}

const timeTableStore = new TimeTableStore();

export default timeTableStore;
