import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import { FB_RULES_LIST_KEY } from './getRulesList';

interface DeleteFlowPayload {
  id: number;
}

interface DeleteFlowResponse {
  success: boolean;
}

export const deleteFlow = async ({ id }: DeleteFlowPayload): Promise<DeleteFlowResponse> => {
  const res = await apiClient.delete<DeleteFlowResponse>(`/flows/${id}`);
  if (isAxiosError(res)) {
    const error = new Error('axios error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

type MutationFnType = (data: DeleteFlowPayload) => ReturnType<typeof deleteFlow>;

interface UseDeleteFlowOptions {
  config?: MutationConfig<MutationFnType>;
}

export const useDeleteFlow = ({ config }: UseDeleteFlowOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: [FB_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: deleteFlow,
  });
};
