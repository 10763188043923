import type { FC } from 'react';
import type { BulkMenuItemProps } from '../types';
import { useEmergencyContext } from '../../emergency';
import { useAccessModify } from '../hooks';
import useChosenRules from '../hooks/useChosenRules';
import { useFormContext } from 'react-hook-form';
import { useModal } from '../../../hooks';
import useBulkRunManually from '../hooks/useBulkRunManually';
import { ListItemText, MenuItem } from '@mui/material';
import ConfirmRunManuallyDialog from './ConfirmRunManuallyDialog';

type RunManuallyMenuItemProps = Required<Pick<BulkMenuItemProps, 'network' | 'closeMenu'>>;

const RunManuallyMenuItem: FC<RunManuallyMenuItemProps> = ({ network, closeMenu }) => {
  const { isEmergency } = useEmergencyContext();
  const { accessRules } = useAccessModify();
  const { isChosenRules } = useChosenRules();
  const { reset } = useFormContext();
  const { open: openRunManually, close: closeRunManually, isOpen: isOpenRunManually } = useModal();

  const handleClose = () => {
    closeRunManually();
    closeMenu();
    reset();
  };

  const { onRunManually, isPending: isPendingRunManually } = useBulkRunManually({
    network,
    onSuccess: handleClose,
    onError: handleClose,
  });
  return (
    accessRules.manually && (
      <>
        <MenuItem disabled={!isChosenRules || isEmergency} onClick={openRunManually}>
          <ListItemText>Run manually</ListItemText>
        </MenuItem>

        <ConfirmRunManuallyDialog
          isOpen={isOpenRunManually}
          isPending={isPendingRunManually}
          close={() => {
            !isPendingRunManually ? handleClose() : null;
          }}
          onConfirm={onRunManually}
        />
      </>
    )
  );
};

export default RunManuallyMenuItem;
