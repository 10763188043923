import type { RulesListParams } from '../types';
import type { QueryKey } from '@tanstack/react-query';
import type { GetChannelsParams } from './types';

export default {
  byFilterValues: (path: string, params: Partial<RulesListParams>) => {
    return [
      path,
      ...Object.keys(params)
        .sort()
        .map((k) => params[k as keyof Partial<RulesListParams>]),
    ] as QueryKey;
  },
  rule: (id: number) => ['ga/rule', id],
  channels: (params: GetChannelsParams) => ['ga/channels', Object.values(params)],
};
