import { apiClient } from 'lib/api';
import { queryOptions } from '@tanstack/react-query';
import type { Rule, RulesListParams } from '../types';
import type { GetRulesListPromise } from '../../types';
import type { QueryFnContext } from 'lib/react-query';

const getRulesList = async ({
  queryKey,
  signal,
}: QueryFnContext<RulesListParams>): Promise<GetRulesListPromise<Rule>> => {
  const params = queryKey[1];

  const res = await apiClient.get<GetRulesListPromise<Rule>>(`/ga/rules`, {
    params,
    signal,
  });

  return res.data;
};

export const GOOGLE_RULES_LIST_KEY = '/ga/rules';

export const getRulesListOptions = (params: RulesListParams) =>
  queryOptions({
    queryFn: getRulesList,
    queryKey: [GOOGLE_RULES_LIST_KEY, params],
    staleTime: 60 * 30 * 1000,
  });
