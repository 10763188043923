import { Skeleton } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { Suspense } from 'react';

interface IProps {
  children: ReactNode;
}

const BasicFieldSkeleton: FC<IProps> = ({ children }) => {
  return (
    <Suspense
      fallback={<Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />}
    >
      {children}
    </Suspense>
  );
};

export default BasicFieldSkeleton;
