import { Box, Typography } from '@mui/material';
import { AccessTimeOutlined } from '@mui/icons-material';

const NameRuleChangedMessage = () => {
  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        alignItems: 'start',
        gap: 1,
        borderRadius: t.borderRadius.md,
        p: 1.5,
        backgroundColor: 'rgba(236, 239, 241, 1)',
        color: 'rgba(1, 67, 97, 1)',
      })}
    >
      <AccessTimeOutlined sx={(t) => ({ color: t.palette.info.main })} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Changing the name will result in the creation of a new rule.
        </Typography>
        <Typography variant="body2">The old rule will be deleted, and it’s logs will be not available</Typography>
      </Box>
    </Box>
  );
};

export default NameRuleChangedMessage;
