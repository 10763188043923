import type { FC } from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import type { GoogleRuleDetails, LevelValue } from '../types';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import type { BasicGoogleValues } from './EditGoogleRule';
import { useRuleForEditor } from '../../hooks';
import { RuleEditorContainer } from '../../components';
import { wellPilotTheme } from '../../utils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  rule: GoogleRuleDetails;
}

const GoogleRuleScriptDialog: FC<IProps> = ({ isOpen, onClose, rule }) => {
  const { getValues } = useFormContext<BasicGoogleValues>();

  const { name, ruleStatus, level, description } = getValues();

  const { code, setCode } = useRuleForEditor({
    content: {
      ...rule,
      name,
      status: ruleStatus,
      description,
      data: {
        ...rule.data,
        level: level as LevelValue,
      },
    },
    network: 'google',
  });

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          id="rule-script-dialog-title"
        >
          <Typography variant="subtitle1">rule script</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <RuleEditorContainer>
          <ReactCodeMirror
            value={code}
            editable={false}
            theme={wellPilotTheme}
            height={`${window.screen.height * 0.7}px`}
            extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
            onChange={setCode}
          />
        </RuleEditorContainer>
      </Box>
    </Dialog>
  );
};

export default GoogleRuleScriptDialog;
