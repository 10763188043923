import type { QueryKey } from '@tanstack/react-query';
import { omit } from 'lodash-es';

export default {
  byFilterValues: <TParams>(path: string, params: Partial<TParams>) => {
    return [
      path,
      ...Object.keys(params)
        .sort()
        .map((k) => params[k as keyof Partial<TParams>]),
    ] as QueryKey;
  },
  byFilterValuesForPlatforms: <TParams>(path: string, params: Partial<TParams>) => {
    return [
      path,
      ...Object.keys(omit(params, ['desc', 'order_by']))
        .sort()
        .map((k) => params[k as keyof Partial<TParams>]),
    ] as QueryKey;
  },
  rule: (id: number) => ['rule', id],
};
