import type { FC } from 'react';
import { useCallback } from 'react';
import { useAccessModify } from '../hooks';
import useChosenRules from '../hooks/useChosenRules';
import useListParams from '../../../hooks/useListParams';
import { useFormContext } from 'react-hook-form';
import { useModal } from '../../../hooks';
import useBulkPause from '../hooks/useBulkPause';
import { ListItemText, MenuItem } from '@mui/material';
import { ConfirmBulkPauseDialog } from './ConfirmBulkPauseDialog';
import type { RuleStatusValue } from '../types';
import type { Network } from 'types/shared';

interface BulkMenuItemProps {
  closeMenu?: () => void;
  status?: RuleStatusValue;
  label: string;
  network: Network;
}

const PauseMenuItem: FC<Pick<BulkMenuItemProps, 'closeMenu' | 'network'>> = ({ closeMenu, network }) => {
  const { accessRules } = useAccessModify();
  const { isChosenRules } = useChosenRules();
  const { tab } = useListParams();

  const { reset } = useFormContext();
  const { open: openPause, close: closePause, isOpen: isOpenPause } = useModal();

  const handleClosePauseDialog = useCallback(() => {
    closeMenu?.();
    closePause();
    reset();
  }, [closeMenu, closePause, reset]);

  const { onPause, isPending: isPendingPause } = useBulkPause({
    network,
    onSuccess: handleClosePauseDialog,
    onError: handleClosePauseDialog,
  });

  return (
    <>
      {accessRules.bulk && ['active', 'paused'].includes(tab as string) ? (
        <MenuItem disabled={!isChosenRules} onClick={openPause}>
          <ListItemText>{tab === 'active' ? 'Pause' : 'Unpause'}</ListItemText>
        </MenuItem>
      ) : null}

      <ConfirmBulkPauseDialog
        isOpen={isOpenPause}
        isPending={isPendingPause}
        close={() => {
          !isPendingPause ? handleClosePauseDialog() : null;
        }}
        onConfirm={onPause}
      />
    </>
  );
};

export default PauseMenuItem;
