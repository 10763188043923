import { Box } from '@mui/material';
import FilterSearchField from 'components/common/FilterSearchField';
import FilterWeekDays from 'components/common/FilterWeekDays';
import FilterLevel from './FilterLevel';
import FilterStatus from './FilterStatus';
import FilterTeams from './FilterTeams';
import FilterPauseDate from './FilterPauseDate';
import FilterArchiveDate from './FilterArchiveDate';
import FilterFromTo from 'components/common/FilterFromTo';
import useListParams from 'hooks/useListParams';
import FilterRuleGroups from './FilterRuleGroups';

const Filters = () => {
  const { setQ, weekday, setWeekday, setFrom, setTo } = useListParams();

  return (
    <Box
      sx={{
        py: 2,
        pr: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        flexGrow: 1,
        gap: 1,
        maxHeight: 70,
        overflow: 'hidden',
        overflowX: 'auto',
      }}
    >
      <FilterSearchField
        containerSx={{ flex: 1, minWidth: 180 }}
        setParam={setQ}
        name="q"
        placeholder="Search by name"
      />
      <FilterWeekDays
        containerSx={{ minWidth: 148, maxWidth: 200 }}
        setParam={setWeekday}
        param={weekday as number[]}
      />
      <FilterFromTo setParam={setFrom} field="timeFrom" />
      <FilterFromTo setParam={setTo} field="timeTo" />
      <FilterArchiveDate />
      <FilterPauseDate />
      <FilterRuleGroups />
      <FilterLevel />
      <FilterStatus />
      <FilterTeams />
    </Box>
  );
};

export default Filters;
