import useChosenRules from 'hooks/useChosenRules';
import { mapValues } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import Checkbox from 'components/common/Checkbox';

const AllRulesCheckbox = () => {
  const { chosenRules, isIndeterminate, onSetIsAllRules, onSetRules } = useChosenRules();

  const handleOnChange = useCallback(
    (checked: boolean) => {
      const allMetricsValue = mapValues(chosenRules, () => checked);
      onSetRules(allMetricsValue);
    },
    [mapValues, onSetRules, chosenRules]
  );

  useEffect(() => {
    onSetIsAllRules(!Object.values(chosenRules ?? {}).includes(false));
  }, [chosenRules]);

  return (
    <Checkbox
      indeterminate={isIndeterminate}
      name={`all_rules_chosen`}
      controlLabelSx={{ '&': { p: 0, m: 0 } }}
      onChange={handleOnChange}
    />
  );
};

export default AllRulesCheckbox;
