/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { ChangeEvent, FC, ReactElement, ReactNode } from 'react';
import { Suspense, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import type { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { ArrowForward as ArrowForwardIcon, ArrowBack as ArrowBackIcon, InfoOutlined } from '@mui/icons-material';
import { RuleStatus } from './';
import type {
  ConditionMetricsProps,
  ConditionIndexProps,
  RuleEntry,
  TaskIndexProps,
  VariantsOnTaskIndexProps,
  VariantOnTaskConditionMetricsProps,
  RuleAction,
} from '../facebook';
import {
  AltRoute as AltRouteIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreOutlined,
} from '@mui/icons-material';
import { useAnchorMenu } from 'hooks';
import RuleCommonAutocomplete from '../facebook/components/RuleCommonAutocomplete';
import type { OptionValue } from 'types/shared';
import { REST_ACTIONS, SUB_ACTIONS } from '../facebook/constants';
import { useConditionsConstants, useConditionsFlows } from '../facebook/api';
import { tasksFormValidation } from '../facebook/utils';
import Autocomplete from 'components/common/Autocomplete';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { ActionsTypeEntry } from '../api/getActionsType';
import { useActionsType } from '../api/getActionsType';
import tasksFormStore from '../facebook/stores/tasksFormStore';
import { useFormContext } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getReferencesOptions } from '../facebook/api/getRefences';
import type { RuleTypeMutation } from '../types';
import { useRuleNavigation } from '../hooks';

export const RuleEditAutoCompleteStyled = styled(RuleCommonAutocomplete)({
  '& .MuiInputBase-root': { minHeight: 36, maxHeight: 36, border: 'none' },
  '& .MuiOutlinedInput-root fieldset': { border: 'none', minHeight: 36, maxHeight: 36, top: 0 },
  '&.MuiAutocomplete-listbox': {
    wordBreak: 'break-all',
  },
  '& .MuiAutocomplete-option': {
    padding: '6px 16px',
    width: '100%',
    wordBreak: 'break-all',
    lineHeight: 1.25,
    boxSizing: 'border-box',
  },
});

export const DependingAutoCompleteStyled = styled(Autocomplete)({
  '& .MuiInputBase-root': { minHeight: 36, maxHeight: 36 },
  '& .MuiOutlinedInput-root fieldset': {
    minHeight: 36,
    maxHeight: 36,
    top: 0,
    borderRadius: 8,
  },
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: 'white',
  },
  '& .MuiAutocomplete-option': {
    padding: '6px 16px',
    wordBreak: 'break-all',
    lineHeight: 1.25,
  },
});

const TextFieldMultiplayerStyled = styled(TextField)({
  '& .MuiOutlinedInput-root': { maxHeight: 36, minHeight: 36, borderRadius: 0 },
  '& fieldset': { border: 'none', top: 0 },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
});

export const ConditionVariantsContainerStyled = styled(Box)(({ theme: t }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'start',
  justifyContent: 'start',
  flexDirection: 'column',
  border: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
  borderRadius: t.borderRadius.md,
}));

type RuleEditTitle = Pick<RuleEntry, 'name' | 'state' | 'status'> & { mode: RuleTypeMutation };
export const RuleEditTitle: FC<RuleEditTitle> = ({ name, state, status, mode }) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const { ruleListURL } = useRuleNavigation();

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 1,
        [t.breakpoints.down('sm')]: {
          pt: 2,
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Link to={ruleListURL} style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowBackIcon sx={(t) => ({ color: t.palette.action.active })} />
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 1 }}>
          {isDirty || mode === 'duplicate' ? (
            <Typography variant="h3" sx={{ p: 0, wordBreak: 'break-all' }}>
              {mode === 'edit' && 'Edit rule'}
              {mode === 'duplicate' && 'Duplicate rule'}
            </Typography>
          ) : (
            <>
              <RuleStatus status={(status === 'active' ? state : status) as never} />
              <Typography variant="h3" sx={{ p: 0, wordBreak: 'break-all' }}>
                {name}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {mode === 'duplicate' && (
        <Typography
          variant="caption"
          sx={(t) => ({
            p: 0,
            wordBreak: 'break-all',
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.5,
            color: t.palette.text.secondary,
          })}
        >
          <InfoOutlined sx={(t) => ({ color: 'rgba(61, 90, 254, 1)', fontSize: t.typography.body2.fontSize })} />
          There cannot be two rules with the same name and conditions. Make changes to save new rule.
        </Typography>
      )}
    </Box>
  );
};

export const TabViewContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 500, // we will skip this value in the future
  paddingTop: 16,
  paddingBottom: 16,
});

export const LabelFormSection: FC<{ label: string; icon: ReactElement }> = ({ label, icon }) => {
  return (
    <Chip
      color="primary"
      label={label}
      icon={icon}
      sx={(t) => ({ py: 2, gap: 0.5, fontWeight: 400, fontSize: t.typography.caption.fontSize })}
    />
  );
};

const VerticalDividerStyled = styled(Box)(({ theme: t }) => {
  return {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    background: t.palette.primary.main,
    borderRadius: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      bottom: 0,
      margin: 'auto',
      left: -11,
      width: 26,
      height: 26,
      background: t.palette.primary.main,
      borderRadius: 13,
      border: `4px solid white`,
    },
  };
});

export const VerticalDivider = () => {
  return (
    <VerticalDividerStyled>
      <ArrowForwardIcon
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: -7,
          margin: 'auto',
          zIndex: 3,
          color: 'white',
          fontSize: 20,
        }}
      />
    </VerticalDividerStyled>
  );
};

export const ConditionFormContainer: FC<{
  title: ReactElement;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  indexCondition: number;
  type?: 'action' | 'condition';
}> = ({ title, sx, children, indexCondition, type = 'condition' }) => {
  return (
    <Box
      id={`${type}-${indexCondition}`}
      sx={(t) => ({
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        flexDirection: 'column',
        border: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
        borderRadius: t.borderRadius.md,
        '.visiblyDeleteButton': { visibility: 'hidden' },
        '&:hover .visiblyDeleteButton': { visibility: 'visible' },
      })}
    >
      <Box
        sx={{
          height: 36,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          py: 2,
          px: 1,
          gap: 2,
          backgroundColor: 'rgba(41, 121, 255, 0.04)',
          borderBottom: `1px solid rgba(41, 121, 255, 0.08)`,
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
          width: '100%',
          px: 1.5,
          py: 2,
          ...(sx ?? {}),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const ConditionVariantForm: FC<{ title: string; sx?: SxProps<Theme>; children?: ReactNode }> = ({
  title,
  sx,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          pb: 1.5,
          px: 0,
        }}
      >
        <Typography variant="subtitle2" sx={(t) => ({ color: t.palette.primary.main, fontWeight: 500 })}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
          width: '100%',
          '&:not(::last-type)': {
            pb: 1,
          },
          ...(sx ?? {}),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const DuplicateTaskButton: FC<TaskIndexProps> = observer(({ indexTask }) => {
  const { isAddTaskAvailable } = tasksFormValidation({ indexTask });
  const handleDuplicateTask = () => {
    tasksFormStore.duplicateTask(indexTask);
  };

  return (
    <IconButton
      size="medium"
      sx={{ p: 0, height: 24, display: 'flex', alignItems: 'end' }}
      onClick={handleDuplicateTask}
      className="visibleByHover"
      disabled={!isAddTaskAvailable}
    >
      <ContentCopyIcon sx={{ fontSize: 22 }} />
    </IconButton>
  );
});

export const AddTaskButton = observer(() => {
  const { isAddTaskAvailable } = tasksFormValidation();

  const handleAddTask = useCallback(() => {
    tasksFormStore.addTask();
  }, []);

  return (
    <Button size="medium" startIcon={<AddIcon />} variant="text" onClick={handleAddTask} disabled={!isAddTaskAvailable}>
      Add task
    </Button>
  );
});

export const AddActionsButton: FC<{ indexTask: number; indexVariant?: number; actions: RuleAction[] }> = observer(
  ({ indexTask, indexVariant, actions }) => {
    const { isAddActionAvailable } = tasksFormValidation({ indexTask });
    const { handleCloseMenu, handleOpenMenu, anchor, isOpen } = useAnchorMenu();

    const { data: actionsType } = useActionsType({
      config: {
        select: (data: ActionsTypeEntry[]) =>
          data.filter(({ name }) => !SUB_ACTIONS.includes(name) || REST_ACTIONS.includes(name)),
      },
    });

    const filteredActionTypes = useMemo(() => {
      const selectedActionIds = actions.filter((item) => item.action);

      return actionsType.filter((item) => !selectedActionIds.some((id) => id.action === item.name));
    }, [actionsType, actions]);

    const handleAddAction = (action: string) => {
      tasksFormStore.addAction({ indexTask, indexVariant, action });
      handleCloseMenu();
    };

    return (
      <>
        <Button
          size="medium"
          startIcon={<AddIcon />}
          variant="text"
          onClick={handleOpenMenu}
          disabled={!isAddActionAvailable}
          endIcon={
            <ExpandMoreOutlined
              sx={(t) => ({
                fontSize: 14,
                color: isAddActionAvailable ? t.palette.primary.main : t.palette.grey[300],
                transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                transition: 'transform .1s',
              })}
            />
          }
        >
          Add action
        </Button>
        <Menu anchorEl={anchor} open={isOpen} onClose={handleCloseMenu} sx={{ maxHeight: '470px' }}>
          {filteredActionTypes.map((item: ActionsTypeEntry) => (
            <MenuItem
              onClick={() => {
                handleAddAction(item.name);
              }}
              key={`Task:${indexTask}.ActionType:${item.id}:${item.name}`}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);

export const DeleteTaskButton: FC<TaskIndexProps> = observer(({ indexTask }) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const handleDeleteTask = () => {
    const handleDeletion = tasksFormStore.deleteTask(indexTask);

    onShowAlert('Task deleted', {
      delayAlert: 3500,
      onUndo: () => {
        handleDeletion.undo();
        onShowInfoAlert('Task recovered', {
          isPending: false,
          delayAlert: 3500,
        });
      },
    });
  };

  return (
    <IconButton size="medium" sx={{ p: 0, height: 24 }} onClick={handleDeleteTask} className="visibleByHover">
      <DeleteIcon sx={{ fontSize: 28, mb: -0.3 }} />
    </IconButton>
  );
});

export const AddConditionButton: FC<{ indexTask: number }> = observer(({ indexTask }) => {
  const { isAddConditionAvailable } = tasksFormValidation({ indexTask });
  const { handleCloseMenu, handleOpenMenu, anchor, isOpen } = useAnchorMenu();
  const addSingleCondition = () => {
    handleCloseMenu();
    tasksFormStore.addSingleCondition(indexTask);
  };

  const addComparisonCondition = () => {
    handleCloseMenu();
    tasksFormStore.addComparisonCondition(indexTask);
  };

  return (
    <>
      <Button
        size="medium"
        startIcon={<AddIcon />}
        variant="text"
        onClick={handleOpenMenu}
        disabled={!isAddConditionAvailable}
        endIcon={
          <ExpandMoreOutlined
            sx={(t) => ({
              fontSize: 14,
              color: isAddConditionAvailable ? t.palette.primary.main : t.palette.grey[300],
              transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
              transition: 'transform .1s',
            })}
          />
        }
      >
        Add condition
      </Button>
      <Menu anchorEl={anchor} open={isOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={addSingleCondition}>Single metric</MenuItem>
        <MenuItem onClick={addComparisonCondition}>Metric compared to metric</MenuItem>
      </Menu>
    </>
  );
});

export const DeleteCondition: FC<{ indexTask: number; indexCondition: number }> = observer(
  ({ indexTask, indexCondition }) => {
    const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

    const handleDeleteCondition = () => {
      const handleDeletion = tasksFormStore.deleteCondition(indexTask, indexCondition);

      onShowAlert('Condition deleted', {
        delayAlert: 3500,
        onUndo: () => {
          handleDeletion.undo();
          onShowInfoAlert('Condition recovered', {
            isPending: false,
            delayAlert: 3500,
          });
        },
      });
    };

    return (
      <IconButton
        size="medium"
        sx={{ p: 0, height: 24 }}
        onClick={handleDeleteCondition}
        className="visiblyDeleteButton"
      >
        <DeleteIcon sx={{ fontSize: 24, mb: -0.3 }} />
      </IconButton>
    );
  }
);

export const DeleteVariantOnTask: FC<VariantsOnTaskIndexProps> = observer(({ indexTask, indexVariant }) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const handleDelete = () => {
    const handleDeletion = tasksFormStore.deleteVariantOnTask(indexTask, indexVariant);

    onShowAlert('Variant of task deleted', {
      delayAlert: 3500,
      onUndo: () => {
        handleDeletion.undo();
        onShowInfoAlert('Variant of task recovered', {
          isPending: false,
          delayAlert: 3500,
        });
      },
    });
  };

  return (
    <IconButton size="medium" sx={{ p: 0, height: 24 }} onClick={handleDelete} className="visiblyDeleteButton">
      <DeleteIcon sx={{ fontSize: 24, mb: -0.3 }} />
    </IconButton>
  );
});

export const ConditionFormContainerTitle: FC<
  ConditionIndexProps & {
    title: string;
    subTitle?: string;
  }
> = ({ title, subTitle, indexCondition, indexTask }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <AltRouteIcon sx={(t) => ({ color: t.palette.primary.main, fontSize: t.typography.h4 })} />
          <Typography variant="subtitle2" sx={(t) => ({ color: t.palette.primary.main, fontWeight: 500 })}>
            {title}
          </Typography>
        </Box>
        {subTitle && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.primary, fontWeight: 400 })}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <DeleteCondition indexCondition={indexCondition} indexTask={indexTask} />
    </Box>
  );
};

export const AddVariantOnTask: FC<TaskIndexProps & { onClick: () => void }> = observer(({ onClick, indexTask }) => {
  const { isAddTaskAvailable } = tasksFormValidation({ indexTask });

  return (
    <Button size="medium" startIcon={<AddIcon />} variant="text" onClick={onClick} disabled={!isAddTaskAvailable}>
      Add variant
    </Button>
  );
});

export const TaskOperator: FC<Pick<ConditionIndexProps, 'indexTask'>> = observer(({ indexTask }) => {
  const handleChangeOperator = (e: ChangeEvent<HTMLInputElement>) => {
    tasksFormStore.changeOperator(indexTask, e.target.value);
  };

  return (
    <RadioGroup
      onChange={handleChangeOperator}
      value={tasksFormStore.getOperator(indexTask)}
      name={`ConditionGroup.${indexTask}`}
      sx={{ display: 'flex', flexDirection: 'row' }}
    >
      <FormControlLabel value="AND" control={<Radio />} label="AND" />
      <FormControlLabel value="OR" control={<Radio />} label="OR" />
    </RadioGroup>
  );
});

export const ConditionMultiplayer: FC<ConditionMetricsProps> = observer(({ indexTask, indexCondition, field }) => {
  const handleChangeMultiplayer = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      tasksFormStore.setConditionMetric(indexTask, indexCondition, field, e.target.value);
    },
    [indexTask, indexCondition, field]
  );

  const fieldValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 0.5 }}>
      <TextFieldMultiplayerStyled
        value={fieldValue ?? ''}
        InputProps={{ inputProps: { min: 0, style: { padding: '0 0 0 3px', textAlign: 'left', borderRadius: 0 } } }}
        onChange={handleChangeMultiplayer}
        type="number"
      />
      <Typography sx={(t) => ({ fontSize: t.typography.body1.fontSize, lineHeight: 0, top: -1, position: 'relative' })}>
        x
      </Typography>
    </Box>
  );
});

export const VariantOnTaskConditionMultiplayer: FC<VariantOnTaskConditionMetricsProps> = observer(
  ({ indexTask, indexVariant, indexCondition, field }) => {
    const fieldValue = tasksFormStore.getVariantOnTaskCondition(indexTask, indexVariant, indexCondition)?.[field];

    const handleChangeMultiplayer = (e: ChangeEvent<HTMLInputElement>) => {
      tasksFormStore.setVariantOnTaskConditionMetric(indexTask, indexVariant, indexCondition, field, e.target.value);
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: 36, px: 1, gap: 0.5 }}>
        <TextFieldMultiplayerStyled
          value={fieldValue ?? ''}
          InputProps={{ inputProps: { min: 0, style: { padding: '0 0 0 3px', textAlign: 'left', borderRadius: 0 } } }}
          onChange={handleChangeMultiplayer}
          type="number"
        />
        <Typography
          sx={(t) => ({ fontSize: t.typography.body1.fontSize, lineHeight: 0, top: -1, position: 'relative' })}
        >
          x
        </Typography>
      </Box>
    );
  }
);

export const ConditionMetrics: FC<ConditionMetricsProps> = observer(({ indexCondition, indexTask, field }) => {
  const { data: metrics } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.conditions.metrics.map(({ name, id }) => ({ value: id, label: name })),
  });

  const handleChangeMetric = useCallback(
    (value: OptionValue) => {
      tasksFormStore.setConditionMetric(indexTask, indexCondition, field, value as string);
    },
    [indexTask, indexCondition, field]
  );

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];
  const value = useMemo(() => storedValue, [storedValue]);

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={value}
      onChange={handleChangeMetric}
      id={`Autocomplete-${field}-${indexTask}-${indexCondition}`}
      options={metrics ?? []}
    />
  );
});

export const ConstantsMetrics: FC<ConditionMetricsProps> = observer(({ indexCondition, indexTask, field }) => {
  const handleChangeMetric = (value: OptionValue) => {
    tasksFormStore.setConditionMetric(indexTask, indexCondition, field, value as string);
  };

  const { data: conditionsConstants } = useConditionsConstants({
    config: { select: (data) => data.map(({ name, id }) => ({ value: id, label: name })) },
  });

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue}
      onChange={handleChangeMetric}
      id={`Autocomplete-${field}-${indexTask}-${indexCondition}`}
      options={conditionsConstants ?? []}
    />
  );
});

export const FlowsMetrics: FC<ConditionMetricsProps> = observer(({ indexCondition, indexTask, field }) => {
  const handleChangeFlow = (value: OptionValue) => {
    tasksFormStore.setConditionMetric(indexTask, indexCondition, field, value as string);
  };

  const { data: conditionsConstants } = useConditionsFlows({
    config: { select: (data) => data.map(({ name, id }) => ({ value: id, label: name })) },
  });

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue}
      onChange={handleChangeFlow}
      id={`Autocomplete-${field}-${indexTask}-${indexCondition}`}
      options={conditionsConstants ?? []}
    />
  );
});

export const LevelsMetrics: FC<ConditionMetricsProps> = observer(({ indexCondition, indexTask, field }) => {
  const handleChangeLevel = (value: OptionValue) => {
    tasksFormStore.setConditionMetric(indexTask, indexCondition, field, value as string);
  };

  const { data: levels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.conditions.levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue}
      onChange={handleChangeLevel}
      id={`Autocomplete-${field}-${indexTask}-${indexCondition}`}
      options={levels ?? []}
    />
  );
});

export const PeriodsMetrics: FC<ConditionMetricsProps> = observer(({ indexCondition, indexTask, field }) => {
  const handleChangeMetric = useCallback(
    (value: OptionValue) => {
      tasksFormStore.setConditionMetric(indexTask, indexCondition, field, value as string);
    },
    [indexTask, indexCondition, field]
  );

  const { data: periods } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.conditions.periods.map(({ name, id }) => ({ value: id, label: name })),
  });

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];

  return (
    <RuleEditAutoCompleteStyled
      allSelectable={false}
      value={storedValue}
      onChange={handleChangeMetric}
      id={`Autocomplete-${field}-${indexTask}-${indexCondition}`}
      options={periods ?? []}
    />
  );
});

export const ConditionCriticalValueField: FC<ConditionIndexProps> = observer(({ indexCondition, indexTask }) => {
  const handleChangeCriticalValue = useCallback(
    (value: OptionValue) => {
      tasksFormStore.setConditionMetric(indexTask, indexCondition, 'critical_value', value as string);
    },
    [indexTask, indexCondition]
  );

  const storedValue = tasksFormStore.getConditionMetric(indexTask, indexCondition).critical_value;

  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <Typography
        variant="body2"
        sx={(t) => ({
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 2,
          top: 0,
          bottom: 0,
          color: t.palette.text.secondary,
          pl: 2,
        })}
      >
        Critical value:
      </Typography>
      <TextField
        value={storedValue ?? ''}
        sx={{
          '&': { width: '100%' },
          '& .MuiInputBase-input': { minHeight: 36, maxHeight: 36, px: 2, py: 0 },
        }}
        InputProps={{ style: { minHeight: 36, maxHeight: 36, borderRadius: '8px', paddingLeft: 95 } }}
        onChange={(e) => {
          handleChangeCriticalValue(e.target.value);
        }}
      />
    </Box>
  );
});

export const VariantOnTaskCriticalValueField: FC<ConditionIndexProps & { indexVariant: number }> = observer(
  ({ indexTask, indexVariant, indexCondition }) => {
    const handleChangeCriticalValue = useCallback(
      (value: OptionValue) => {
        tasksFormStore.setVariantOnTaskConditionMetric(
          indexTask,
          indexVariant,
          indexCondition,
          'critical_value',
          value as string
        );
      },
      [indexTask, indexVariant, indexCondition]
    );

    const storedValue = tasksFormStore.getVariantOnTaskCondition(
      indexTask,
      indexVariant,
      indexCondition
    ).critical_value;

    return (
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Typography
          variant="body2"
          sx={(t) => ({
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 2,
            top: 0,
            bottom: 0,
            color: t.palette.text.secondary,
            pl: 2,
          })}
        >
          Critical value:
        </Typography>
        <TextField
          value={storedValue ?? ''}
          sx={{
            '&': { width: '100%' },
            '& .MuiInputBase-input': { minHeight: 36, maxHeight: 36, px: 2, py: 0 },
          }}
          InputProps={{ style: { minHeight: 36, maxHeight: 36, borderRadius: '8px', paddingLeft: 95 } }}
          onChange={(e) => {
            handleChangeCriticalValue(e.target.value);
          }}
        />
      </Box>
    );
  }
);

const ConditionOperatorSelect: FC<{ onChange: (e: SelectChangeEvent) => void; value?: string }> = ({
  onChange,
  value,
}) => {
  const formatName = useCallback((name: string) => {
    const formattedName = name.replace(/_/g, ' ').toLowerCase();
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
  }, []);

  const { data: operators } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.conditions.operators.map(({ id, name }) => ({ id, name: formatName(name) })),
  });

  return (
    <Select
      variant="outlined"
      value={value}
      onChange={onChange}
      sx={(t) => ({
        '&.MuiInputBase-root, &.MuiInputBase-root fieldset': { border: 'none' },
        '&.MuiInputBase-root': {
          minHeight: 24,
          maxHeight: 24,
          borderRadius: t.borderRadius.md,
          backgroundColor: t.palette.grey[50],
          mt: 0.25,
        },
      })}
    >
      {operators.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const ConditionOperator: FC<{
  onChange: (e: SelectChangeEvent) => void;
  value?: string;
  isSingleCondition?: boolean;
}> = observer(({ value, onChange, isSingleCondition }) => {
  const iconMapper = useRef<Record<string, string>>({
    LESS_THAN: '<',
    GREATER_THAN: '>',
    DOES_NOT_EQUAL: '≠',
    EQUAL: '=',
    GREATER_THAN_OR_EQUAL: '≥',
    LESS_THAN_OR_EQUAL: '≤',
    default: '.',
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, pt: 3, pb: 2.5 }}>
      <Box
        sx={(t) => ({
          position: 'relative',
          '&:after': !isSingleCondition
            ? {
                content: "''",
                position: 'absolute',
                zIndex: 1,
                top: -16,
                bottom: -18,
                left: 13,
                width: 2,
                background: t.palette.divider,
                borderRadius: 2,
              }
            : {},
        })}
      >
        <Box
          sx={(t) => ({
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 28,
            minWidth: 28,
            maxWidth: 28,
            height: 28,
            borderRadius: 14,
            backgroundColor: t.palette.grey[50],
            border: '4px solid white',
          })}
        >
          <Typography component="span" sx={(t) => ({ fontSize: t.typography.body1.fontSize, lineHeight: 0 })}>
            {iconMapper.current[value ?? 'default']}
          </Typography>
        </Box>
      </Box>

      <Suspense
        fallback={
          <Box
            sx={{
              width: '140px',
              height: 24,
              mt: 0.25,
            }}
          >
            <Skeleton
              width={'100%'}
              height={24}
              sx={(t) => ({
                transform: 'translateY(0)',
                borderRadius: t.borderRadius.md,
                backgroundColor: t.palette.grey[50],
                m: 0,
              })}
            />
          </Box>
        }
      >
        <ConditionOperatorSelect value={value} onChange={onChange} />
      </Suspense>
    </Box>
  );
});

export const TaskConditionOperator: FC<ConditionIndexProps & { isSingleCondition?: boolean }> = observer(
  ({ indexCondition, indexTask, isSingleCondition }) => {
    const handleChangeOperator = useCallback(
      (e: SelectChangeEvent) => {
        tasksFormStore.setConditionMetric(indexTask, indexCondition, 'operator', e.target.value);
      },
      [indexTask, indexCondition]
    );

    const currentValue = tasksFormStore.getConditionMetric(indexTask, indexCondition).operator;

    return (
      <ConditionOperator isSingleCondition={isSingleCondition} value={currentValue} onChange={handleChangeOperator} />
    );
  }
);

export const VariantTaskConditionOperator: FC<
  VariantsOnTaskIndexProps & { indexCondition: number; isSingleCondition?: boolean }
> = observer(({ indexVariant, indexTask, indexCondition, isSingleCondition }) => {
  const handleChangeOperator = useCallback(
    (e: SelectChangeEvent) => {
      tasksFormStore.changeVariantTaskConditionOperator(indexTask, indexVariant, indexCondition, e.target.value);
    },
    [indexTask, indexCondition, indexVariant]
  );

  const currentValue = tasksFormStore.getVariantOnTaskCondition(indexTask, indexVariant, indexCondition).operator;

  return (
    <ConditionOperator isSingleCondition={isSingleCondition} value={currentValue} onChange={handleChangeOperator} />
  );
});
