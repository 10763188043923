import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModal } from '../../../hooks';
import useChosenRules from '../hooks/useChosenRules';
import useBulkArchive from '../hooks/useBulkArchive';
import type { RulesFiltersParams } from '../facebook/api/types';
import { ListItemText, MenuItem } from '@mui/material';
import ConfirmBulkArchivingDialog from './ConfirmBulkArchivingDialog';
import type { BulkMenuItemProps } from '../types';

const BulkArchiveMenuItem: FC<BulkMenuItemProps> = ({ network, closeMenu, status, label }) => {
  const { reset } = useFormContext();
  const { open, close: closeArchiveManually, isOpen } = useModal();
  const { isChosenRules } = useChosenRules();

  const handleClose = () => {
    closeArchiveManually();
    closeMenu?.();
    reset();
  };
  const { onArchiveManually, isPending } = useBulkArchive<RulesFiltersParams>({
    network,
    status,
    onSuccess: handleClose,
    onError: handleClose,
  });

  return (
    <>
      <MenuItem disabled={!isChosenRules} onClick={open}>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
      <ConfirmBulkArchivingDialog
        status={status}
        isOpen={isOpen}
        isPending={isPending}
        close={() => {
          !isPending ? handleClose() : null;
        }}
        onConfirm={onArchiveManually}
      />
    </>
  );
};

export default BulkArchiveMenuItem;
