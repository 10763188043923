import { useMyAccess } from 'features/users';
import useListParams from 'hooks/useListParams';

const useAccessModify = () => {
  const { project, tab } = useListParams();
  const myAccess = useMyAccess(project ? { id: parseInt(project) } : undefined);

  const accessRules = {
    pause: myAccess?.rules.includes('rule_start-stop') && tab !== 'paused',
    unpause: myAccess?.rules.includes('rule_start-stop') && tab === 'paused',
    duplicate: myAccess?.rules.includes('create'),
    archive: myAccess?.rules.includes('rule_archive') && tab !== 'archived',
    unarchive: myAccess?.rules.includes('rule_archive') && tab === 'archived',
    manually: myAccess?.rules.includes('rule_run') && tab !== 'archived' && tab !== 'paused',
  };

  return {
    myAccess,
    accessRules,
  };
};

export default useAccessModify;
