import { useParams } from 'react-router';
import type { Network } from 'types/shared';

const useCurrentNetwork = (): Network => {
  const { network } = useParams();

  if (!network) {
    const retainedNetwork = localStorage.getItem('_network') as Network;
    return retainedNetwork ? (retainedNetwork as Network) : 'facebook';
  }

  return network as Network;
};

export default useCurrentNetwork;
