import type { FC } from 'react';
import useChosenRules from '../hooks/useChosenRules';
import useListParams from 'hooks/useListParams';
import { useProjects } from 'features/projects';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Typography } from '@mui/material';
import type { UseModalResult } from 'hooks';
import type { RuleStatusValue } from '../types';

interface ConfirmBulkDialog extends Pick<UseModalResult, 'close' | 'isOpen'> {
  errorMessage?: string;
  isPending: boolean;
  onConfirm: () => void;
  status?: RuleStatusValue;
}

export const ConfirmBulkPauseDialog: FC<ConfirmBulkDialog> = ({
  errorMessage,
  isOpen,
  isPending,
  close,
  onConfirm,
}) => {
  const { rulesIds, isIndeterminate } = useChosenRules();
  const { tab, project } = useListParams();
  const { data: listProjects } = useProjects<Record<string, string>>({
    config: {
      select: (data) => data.reduce((accum, { name, id }) => ({ ...accum, [id]: name }), {}),
    },
  });

  const generateTitle = (tab: string | null | undefined, rulesIds: number[], project: string) => {
    const projectTitle = project === `all_projects` ? `all projects` : `${listProjects[project]} project ?`;
    const actionTitle = tab === 'active' ? 'Pause' : 'Unpause';

    return `${actionTitle} ${!isIndeterminate ? `all rules in ${projectTitle}` : `${rulesIds.length} rules in ${projectTitle}`}`;
  };

  return (
    <ConfirmDialog
      title={generateTitle(tab, rulesIds, project)}
      text={
        <>
          {tab === 'active' ? (
            <Typography variant="body1">
              You will be able to find particular paused rules using the “Pause date“ date filter in the “Paused” tab.
            </Typography>
          ) : (
            <Typography variant="body1">Rules will become active and perform their actions.</Typography>
          )}
          {!!errorMessage && (
            <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
              {errorMessage}
            </Typography>
          )}
        </>
      }
      confirmButton={tab === 'active' ? 'Pause rules' : 'Unpause rules'}
      cancelButton="Cancel"
      isOpen={isOpen}
      isPending={isPending}
      close={close}
      onCancel={close}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmBulkPauseDialog;
