import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import RadioGroup from 'components/common/RadioGroup';
import type { FC } from 'react';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { ProjectSelect } from 'features/projects';
import { useFormContext } from 'react-hook-form';
import ChipInput from 'components/common/ChipInput';
import { ExpandMore } from '@mui/icons-material';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { GoogleRuleDetails } from '../types';
import { getReferencesOptions } from '../../facebook';
import type { RuleTypeMutation } from '../../types';
import BasicFieldSkeleton from '../../components/BasicFieldSkeleton';
import { NameRuleChangedMessage, LabelFormSection, BasicSummary } from '../../components';

const LevelSelect = () => {
  const { data: levels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  return <Select name="level" inputLabel="level" required selectAll={false} options={levels} />;
};

const BasicValues: FC<{ rule: GoogleRuleDetails; mode: RuleTypeMutation }> = ({ rule, mode }) => {
  const { getFieldState } = useFormContext();
  const isNameDirty = getFieldState('name').isDirty && mode === 'edit';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Rule settings
        </Typography>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
            Status
          </Typography>
          <RadioGroup
            row
            name="ruleStatus"
            options={[
              { value: 'active', label: 'Active' },
              { value: 'paused', label: 'Paused' },
              { value: 'archived', label: 'Archived' },
            ]}
          />
        </Box>
      </Box>
      <Box
        sx={(t) => ({
          display: 'flex',
          gap: 2,
          width: '100%',
          flexDirection: 'column',
          border: `solid ${`rgba(0, 8, 46, 0.12)`}`,
          borderRadius: t.borderRadius.md,
          p: 2,
        })}
      >
        <Box>
          <LabelFormSection label="Basic" icon={<FeaturedPlayListOutlinedIcon />} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', gap: 4 }}>
          <Box sx={{ display: 'flex', width: 'calc(60% - 50px)', gap: 3, flexDirection: 'column' }}>
            <Input name="name" label="name" innerLabel />
            {isNameDirty && <NameRuleChangedMessage />}
            <BasicFieldSkeleton>
              <ProjectSelect onlyUsersProjects name="project" inputLabel="project" required />
            </BasicFieldSkeleton>
            <BasicFieldSkeleton>
              <LevelSelect />
            </BasicFieldSkeleton>
            <ChipInput name="adAccountIds" inputLabel="ad_account_ids" />
            <Select
              name="type"
              inputLabel="type"
              required
              selectAll={false}
              options={[
                { value: 'notify', label: 'notify' },
                { value: 'action', label: 'action' },
              ]}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <Typography variant="subtitle2">Notifications</Typography>
              <BasicFieldSkeleton>
                <Accordion
                  disableGutters
                  sx={(t) => ({
                    borderBottom: `1px solid ${t.palette.divider}`,
                    '&:last-of-type': { borderRadius: 0 },
                    pb: 1.5,
                  })}
                >
                  <AccordionSummary
                    sx={{ py: 0, pl: 1.5, pr: 1, minHeight: 32, '& .MuiAccordionSummary-content': { my: 0 } }}
                    expandIcon={<ExpandMore />}
                    id={`accordionPanel_${rule.id}`}
                  >
                    <Typography variant="subtitle2" sx={(t) => ({ color: t.palette.text.secondary })}>
                      Notification message
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 1 }}>
                      <Input name="notificationMessage.title" label="notification title" innerLabel />
                      <Input name="notificationMessage.body" label="notification body" innerLabel multiline />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </BasicFieldSkeleton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, gap: 3, flexDirection: 'column' }}>
            <BasicSummary actionTypes={[{ name: rule.data.type, id: rule.data.type }]} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="subtitle1">Description</Typography>
              <Box sx={{ py: 1.5, borderRadius: 1.5 }}>
                <Input id="description" name="description" multiline />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicValues;
