import { Box, Grid } from '@mui/material';
import { Suspense, type FC, useEffect } from 'react';
import RulesTable from './RulesTable';
import TitlePageStyles from 'components/common/TitlePageStyles';
import Filters from './Filters';
import FilterPlatforms from './FilterPlatforms';
import RulesTableSkeleton from './RulesTableSkeleton';
import FilterValues from './FilterValues';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { FilterFormData } from '../types';
import { filterParamsSchema } from '../validation';
import useListParams from 'hooks/useListParams';
import FiltersSkeleton from './FiltersSkeleton';
import FilterValuesSkeleton from './FilterValuesSkeleton';
import { FilterProjects, FilterProjectsSkeleton } from 'features/projects';
import RulesSidePanelSkeleton from 'components/common/RulesSidePanelSkeleton';
import RulesStatusTabsSkeleton from 'components/common/RulesStatusTabsSkeleton';
import RulesStatusTabs from 'components/common/RulesStatusTabs';

const Rules: FC = () => {
  const {
    q,
    project,
    timeFrom,
    timeTo,
    weekday,
    pauseDate,
    archiveDate,
    action,
    level,
    statusState,
    team,
    listId,
    platform,
    groups,
  } = useListParams();

  const formMethods = useForm<FilterFormData>({
    resolver: yupResolver(filterParamsSchema),
    defaultValues: {
      project: project ?? 'all_projects',
      q: q ?? '',
      weekday: (weekday as number[]) ?? [],
      archiveDate: archiveDate ?? '',
      pauseDate: pauseDate ?? '',
      timeFrom: timeFrom,
      timeTo: timeTo,
      action: action ? (action as string[]) : [],
      level: level ? (level as string[]) : [],
      statusState: statusState ? (statusState as string[]) : [],
      groups: groups ? groups.map(Number) : [],
      team: team ? (team as string[]) : [],
      listId: listId ? (listId as number[]) : [],
      platform: platform,
    },
  });

  const { setValue, watch } = formMethods;
  const projectFormField = watch('project');

  useEffect(() => {
    projectFormField !== project && setValue('project', project);
  }, [project]);

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ px: 3 }}>
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'end', mb: 1 }}>
          <TitlePageStyles>Rules</TitlePageStyles>
          <Box>
            <Suspense fallback={<FilterProjectsSkeleton />}>
              <FilterProjects />
            </Suspense>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'start', flexDirection: 'column' }}>
          <Suspense fallback={<RulesStatusTabsSkeleton />}>
            <RulesStatusTabs />
          </Suspense>
          <Suspense fallback={<FiltersSkeleton />}>
            <Filters />
          </Suspense>
          <Suspense fallback={<FilterValuesSkeleton />}>
            <FilterValues />
          </Suspense>
        </Box>
      </Box>

      <Grid container flex={1} sx={{ px: 3 }}>
        <Suspense fallback={<RulesSidePanelSkeleton title="Directory" />}>
          <FilterPlatforms />
        </Suspense>
        <Grid
          item
          container
          sx={(t) => ({
            flex: 1,
            borderTop: `1px solid ${t.palette.grey[300]}`,
            borderLeft: `1px solid ${t.palette.grey[300]}`,
          })}
        >
          <Suspense fallback={<RulesTableSkeleton />}>
            <RulesTable />
          </Suspense>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default Rules;
