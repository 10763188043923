import { Box, List, Drawer } from '@mui/material';
import LogoAutoRules from 'assets/logo-autorules.svg?react';
import { useEffect, type FC, useRef, ReactNode, useMemo } from 'react';
import { AutoAwesome as RulesIcon, ModeStandby as ModeStandbyIcon } from '@mui/icons-material';
import SidebarNavItem from './SidebarNavItem';
import SettingsNav from './SettingsNav';
import UserNav from './UserNav';
import { useModal } from 'hooks';
import TopBar from './TopBar';
import { useLocation } from 'react-router';
import { useBreakpoints } from 'hooks';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useCurrentNetwork } from 'hooks';
import { Network } from 'types/shared';

const Sidebar: FC = () => {
  const [project] = useQueryParam('project', withDefault(StringParam, 'all_projects'));
  const network = useCurrentNetwork();
  const location = useLocation();
  const { isOpen, open, close, toggle } = useModal();
  const { isBreakpoint } = useBreakpoints('sm');

  useEffect(() => {
    isBreakpoint ? close() : open();
  }, [isBreakpoint, close, open]);

  useEffect(() => {
    close();
  }, [location, close]);

  const RulesMenuItem = useMemo(
    () => (
      <SidebarNavItem
        to={`/networks/${network}/rules?project=${project}`}
        title="Rules"
        icon={<RulesIcon style={{ fontSize: 24, padding: 0 }} />}
        style={{ marginBottom: 12 }}
      />
    ),
    [project, network]
  );

  const MetricsMenuItem = useMemo(
    () => (
      <SidebarNavItem
        to={`/metrics?project=${project}`}
        title="Metrics"
        icon={<ModeStandbyIcon style={{ fontSize: 24, padding: 0 }} />}
        style={{ marginBottom: 12 }}
      />
    ),
    [project]
  );

  const MenuItems = useRef<Record<Network, ReactNode>>({
    facebook: (
      <>
        {RulesMenuItem}
        {MetricsMenuItem}
      </>
    ),
    google: <>{RulesMenuItem}</>,
  });

  return (
    <>
      {isBreakpoint && <TopBar onToggleMenu={toggle} />}
      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={close}
        variant={isBreakpoint ? 'temporary' : 'permanent'}
        elevation={0}
        sx={(t) => ({
          position: 'relative',
          zIndex: 7,
          flexShrink: 0,
          width: 80,
          [`& .MuiDrawer-paper`]: {
            position: isBreakpoint ? 'fixed' : 'relative',
            borderRightColor: isBreakpoint ? 'transparent' : t.palette.grey[50],
            overflow: 'hidden',
            borderRadius: 1,
            [t.breakpoints.down('sm')]: {
              left: 8,
              top: 8,
              bottom: 8,
              height: 'auto',
            },
          },
          [`& .MuiModal-backdrop`]: {
            backgroundColor: 'rgba(43, 43, 43, 0.20)',
          },
        })}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          component="nav"
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexGrow: 1,
            maxWidth: 80,
            width: 80,
            pt: isBreakpoint ? 0 : 4,
            pb: isBreakpoint ? 2 : 3,
            height: '100vh',
            backgroundColor: 'white',
            borderRadius: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <List sx={{ '&': { p: 0, display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' } }}>
              {MenuItems.current[network as Network]}
            </List>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
            <LogoAutoRules width={80} height={110} />
            <SettingsNav />
            <UserNav />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
