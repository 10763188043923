import { Divider, List, Skeleton } from '@mui/material';
import type { FC } from 'react';

const FiltersChannelsSkeleton: FC = () => {
  return (
    <List disablePadding>
      <Skeleton width={'100%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '8px', mb: 2 }} />
      <Divider sx={{ my: 2.5 }} />
      <Skeleton width={'100%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '8px', mb: 2 }} />
      <Skeleton width={'100%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '8px', mb: 2 }} />
      <Skeleton width={'100%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '8px', mb: 2 }} />
      <Skeleton width={'100%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '8px', mb: 2 }} />
    </List>
  );
};

export default FiltersChannelsSkeleton;
