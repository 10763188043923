import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import type { Network } from 'types/shared';
import initApiNetwork from 'utils/initApiNetwork';
import { GOOGLE_RULES_LIST_KEY } from '../google/api/getRulesList';
import { FB_RULES_LIST_KEY } from '../facebook/api/getRulesList';

export interface TriggerRuleResponse {
  message?: string;
}

interface UpdateRulePayload {
  ruleId: number;
}

export const triggerRule = async ({ ruleId }: UpdateRulePayload, network: Network): Promise<TriggerRuleResponse> => {
  const apiNetwork = initApiNetwork(network);
  const res = await apiClient.post<TriggerRuleResponse>(`${apiNetwork}/rules/${ruleId}/trigger`);
  return res.data;
};

interface UseTriggerRuleOptions {
  network: Network;
  config?: MutationConfig<typeof triggerRule>;
}

export const useTriggerRule = ({ network, config }: UseTriggerRuleOptions = { network: 'facebook' }) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.rule(args[1].ruleId),
        }),
        queryClient.invalidateQueries({
          queryKey: network === 'google' ? [GOOGLE_RULES_LIST_KEY] : [FB_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ ruleId }) => triggerRule({ ruleId }, network),
  });
};
