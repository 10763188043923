import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { BulkActionRulesParams } from '../types';
import type { Network } from 'types/shared';
import initApiNetwork from 'utils/initApiNetwork';
import { GOOGLE_RULES_LIST_KEY } from '../google/api/getRulesList';
import { FB_RULES_LIST_KEY } from '../facebook/api/getRulesList';

interface RunRulesResponse {
  message: string;
}

export const runRules = async <TRulesFiltersParams>(
  params: BulkActionRulesParams<TRulesFiltersParams>,
  network: Network
): Promise<RunRulesResponse> => {
  const apiNetwork = initApiNetwork(network);
  const res = await apiClient.post<{ data: RunRulesResponse }>(`${apiNetwork}/rules/trigger`, params);
  return res.data.data;
};

interface UseRunRulesOptions {
  network: Network;
  config?: MutationConfig<typeof runRules>;
}

export const useRunRules = <TRulesFiltersParams>({ config, network }: UseRunRulesOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: network === 'google' ? [GOOGLE_RULES_LIST_KEY] : [FB_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (params: BulkActionRulesParams<TRulesFiltersParams>) => runRules(params, network),
  });
};
