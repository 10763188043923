import type { RuleData } from '../facebook/types';
import { normalizeObject } from './index';
import orderRule from './orderRule';

const compareRule = (ruleData: RuleData, ruleCode: string) => {
  try {
    return (
      JSON.stringify(orderRule(normalizeObject(ruleData) as RuleData)) ===
      JSON.stringify(orderRule(JSON.parse(ruleCode) as RuleData))
    );
  } catch {
    return true;
  }
};

export default compareRule;
