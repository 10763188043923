import * as yup from 'yup';
import { flowValidation } from 'utils/flowValidation';
import type { TestFunction } from 'yup';

const editRuleSchema = yup.object().shape({
  ruleName: yup.string().required().min(1).label("Can't be empty"),
  description: yup.string(),
  ruleStatus: yup.string().required().oneOf(['active', 'paused', 'archived']),
  project: yup.number().required().label('Project'),
  flows: yup
    .array()
    .label('Flows')
    .test('flowValidation', flowValidation as TestFunction),
});

export default editRuleSchema;
