import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { GoogleRuleDetails } from '../types';
import { queryOptions } from '@tanstack/react-query';

export const getGoogleRuleDetails = async (id: number, signal?: AbortSignal): Promise<GoogleRuleDetails> => {
  const res = await apiClient.get<GoogleRuleDetails>(`ga/rules/${id}`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const getGoogleRuleDetailsOptions = (id: number) =>
  queryOptions({
    queryFn: ({ signal }) => getGoogleRuleDetails(id, signal),
    queryKey: ['google-rules', { id }],
    staleTime: 60 * 30 * 1000,
  });
