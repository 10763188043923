import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import normalizeObject from '../utils/normalizeObject';
import type { RuleData } from '../facebook/types';
import orderRule from '../utils/orderRule';
import type { Network } from '../../../types/shared';
import type { GoogleRuleDetails } from '../google/types';

interface RuleEditor {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  prettify: () => string;
}

interface IProps {
  content: RuleData | GoogleRuleDetails;
  network: Network;
}
const useRuleForEditor = ({ content, network }: IProps): RuleEditor => {
  const [code, setCode] = useState('{}');

  const prettify = () => {
    return JSON.stringify(JSON.parse(code), null, 2);
  };

  useEffect(() => {
    if (content) {
      if (network === 'facebook') {
        setCode(JSON.stringify(normalizeObject(orderRule(content as RuleData)), null, 2));
      } else {
        setCode(JSON.stringify(normalizeObject(content), null, 2));
      }
    }
  }, [content, network]);

  return { code, setCode, prettify };
};

export default useRuleForEditor;
