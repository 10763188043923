import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import type { FC } from 'react';
import type { TabsParam } from 'types/shared';
import { SwitchChosenRulesVisibility } from './RulesComponents';
import RulesTableActionMenu from './RulesTableMenu';
import { BooleanParam, useQueryParam } from 'use-query-params';
import AllRulesCheckbox from 'components/common/AllRulesCheckbox';

interface RuleTableHeadProps {
  handleToggleSort: (key: string) => void;
  orderBy: string | null | undefined;
  desc: boolean;
  tab: TabsParam | null | undefined;
}

const nextRunLabel = (
  <Typography variant="body2" fontWeight="500">
    Next run
  </Typography>
);

const RulesTableHead: FC<RuleTableHeadProps> = ({ handleToggleSort, desc, orderBy, tab }: RuleTableHeadProps) => {
  const [flowsOpen] = useQueryParam('flowsOpen', BooleanParam);
  const isActiveOrAll = tab === 'active' || tab === 'all';
  const isPaused = tab === 'paused';
  const isArchived = tab === 'archived';

  return (
    <TableHead sx={{ position: 'relative', zIndex: 3 }}>
      <TableRow sx={{ th: { height: 56, border: 'none', fontWeight: 'bold', py: 1, px: 1 } }}>
        <TableCell align="center" sx={{ width: 52, maxWidth: 52 }}>
          <AllRulesCheckbox />
        </TableCell>
        <TableCell align="center" sx={{ width: 46, maxWidth: 46 }}>
          <SwitchChosenRulesVisibility />
        </TableCell>
        <TableCell sx={{ minWidth: '200px', maxWidth: flowsOpen ? '300px' : '400px' }}>
          <Typography variant="body2" fontWeight="500">
            Name
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="500">
            Status
          </Typography>
        </TableCell>
        {isPaused && (
          <TableCell>
            <TableSortLabel
              sx={{ width: '100%' }}
              active={orderBy === 'paused_at'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('paused_at');
              }}
            >
              <Typography variant="body2" fontWeight="500">
                Paused
              </Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {isArchived && (
          <TableCell>
            <TableSortLabel
              sx={{ width: '100%' }}
              active={orderBy === 'archived_at'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('archived_at');
              }}
            >
              <Typography variant="body2" fontWeight="500">
                Archived
              </Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {isActiveOrAll && (
          <TableCell>
            <TableSortLabel
              sx={{ width: '100%' }}
              active={orderBy === 'last_run'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('last_run');
              }}
            >
              <Typography variant="body2" fontWeight="500">
                Last run
              </Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell>
          {isActiveOrAll ? (
            <TableSortLabel
              sx={{ width: '100%' }}
              active={orderBy === 'next_run'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('next_run');
              }}
            >
              {nextRunLabel}
            </TableSortLabel>
          ) : (
            nextRunLabel
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Rule group
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Flow
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Level
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ pr: 0 }}>
          <RulesTableActionMenu />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default RulesTableHead;
