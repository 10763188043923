import * as yup from 'yup';

const basicValues = yup.object().shape({
  name: yup.string().required().min(1).label("Can't be empty"),
  project: yup.number().required().label('project'),
  level: yup.string().required().oneOf(['adgroup', 'ad', 'campaign']).label('level'),
  description: yup.string(),
  notificationMessage: yup.object().shape({
    title: yup.string().nullable().default(''),
    body: yup.string().nullable().default(''),
  }),
  ad_account_ids: yup.array(yup.string()),
  ruleStatus: yup.string().required().oneOf(['paused', 'archived', 'active']),
});

export default basicValues;
