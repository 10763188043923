import { Box, CssBaseline, Grid, Typography } from '@mui/material';
import IconLogo from 'assets/icon-logo.svg?react';
import { BackgroundBox, NetworkItem, TitleStyled } from './NetworkComponents';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const Networks = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const email = user.getSignInUserSession()?.getIdToken().payload.email as string;
  return (
    <>
      <CssBaseline />
      <Grid
        sx={{
          flexDirection: 'row',
          height: '100vh',
          minWidth: 320,
          p: 1,
          background: 'rgba(156, 39, 176, 0.04)',
        }}
        container
      >
        <Grid sx={{ height: '100%' }} item container xs={6}>
          <BackgroundBox>
            <IconLogo width={140} />
            <TitleStyled>Campaigns automation</TitleStyled>
          </BackgroundBox>
        </Grid>
        <Grid item container sx={{ flexDirection: 'column', alignItems: 'center', pt: 5, pb: 10 }} xs={6}>
          <Typography sx={{ display: 'flex', alignItems: 'center', px: 5 }} variant="body1" color="text.secondary">
            <AccountCircleOutlinedIcon sx={{ mr: 3 }} />
            {email}
          </Typography>
          <Box
            sx={{
              px: 5,
              width: '75%',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2" sx={{ mb: 10 }}>
              Choose network
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <NetworkItem title="Facebook" link="networks/facebook/rules" />
              <NetworkItem title="Google" link="networks/google/rules" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Networks;
