import type { State, Status, TabsParam } from 'types/shared';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  createEnumParam,
  createEnumArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';

const getInitialOrderBy = (tab: string | null | undefined) => {
  if (tab === 'paused') {
    return 'paused_at';
  }
  if (tab === 'archived') {
    return 'archived_at';
  } else {
    return 'next_run';
  }
};

const useListParams = () => {
  const [newMetric, setNewMetric] = useQueryParam('newMetric', withDefault(BooleanParam, false));
  const [project, setProject] = useQueryParam('project', withDefault(StringParam, 'all_projects'));
  const [tab, setTab] = useQueryParam(
    'tab',
    withDefault(createEnumParam<TabsParam>(['active', 'archived', 'paused', 'all']), 'active')
  );
  const [q, setQ] = useQueryParam('q', withDefault(StringParam, undefined));
  const [weekday, setWeekday] = useQueryParam('weekday', withDefault(NumericArrayParam, []));
  const [action, setAction] = useQueryParam('action', withDefault(NumericArrayParam, []));
  const [level, setLevel] = useQueryParam('level', withDefault(ArrayParam, []));
  const [statusState, setStatusState] = useQueryParam(
    'statusState',
    withDefault(
      createEnumArrayParam<Status | State>(['active', 'archived', 'paused', 'completed', 'running', 'failed']),
      []
    )
  );
  const [team, setTeam] = useQueryParam('team', withDefault(NumericArrayParam, []));
  const [timeFrom, setFrom] = useQueryParam('time_from', withDefault(StringParam, undefined));
  const [timeTo, setTo] = useQueryParam('time_to', withDefault(StringParam, undefined));
  const [pauseDate, setPauseDate] = useQueryParam('pauseDate', withDefault(StringParam, undefined));
  const [archiveDate, setArchiveDate] = useQueryParam('archiveDate', withDefault(StringParam, undefined));
  const [platform, setPlatform] = useQueryParam('platform', withDefault(NumberParam, undefined));
  const [listId, setListId] = useQueryParam('listId', withDefault(ArrayParam, []));
  const [groupId, setGroupId] = useQueryParam('groupId', withDefault(StringParam, undefined));
  const [groups, setGroups] = useQueryParam('groups', withDefault(ArrayParam, undefined));
  const [newFlow, setNewFlow] = useQueryParam('new_flow', withDefault(NumberParam, undefined));
  const [channel, setChannel] = useQueryParam('channel', withDefault(StringParam, undefined));

  const [orderBy, setOrderBy] = useQueryParam('order_by', withDefault(StringParam, getInitialOrderBy(tab)));
  const [desc, setDesc] = useQueryParam('desc', withDefault(BooleanParam, false));

  return {
    project,
    setProject,
    tab,
    setTab,
    q,
    setQ,
    weekday,
    setWeekday,
    action,
    setAction,
    level,
    setLevel,
    statusState,
    setStatusState,
    team,
    setTeam,
    timeFrom,
    setFrom,
    timeTo,
    setTo,
    pauseDate,
    setPauseDate,
    archiveDate,
    setArchiveDate,
    platform,
    setPlatform,
    listId,
    setListId,
    groupId,
    setGroupId,
    groups,
    setGroups,
    newFlow,
    setNewFlow,
    newMetric,
    setNewMetric,
    channel,
    setChannel,
    orderBy,
    setOrderBy,
    desc,
    setDesc,
  };
};

export default useListParams;
