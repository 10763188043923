import useListParams from 'hooks/useListParams';
import useChosenRules from 'hooks/useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useEditRules } from '../api/editRules';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import useRulesQueryParams from './useRulesQueryParams';
import type { RuleStatusValue } from '../types';
import type { Network } from 'types/shared';
import type { BulkActionRulesParams } from '../types';

const useBulkArchive = <TRulesFiltersParams>({
  network,
  status,
  onSuccess,
  onError,
}: {
  network: Network;
  status?: RuleStatusValue;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { reset } = useFormContext();
  const { tab } = useListParams();
  const { rulesIds, isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams<TRulesFiltersParams>();

  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const editRulesMutation = useEditRules<BulkActionRulesParams<TRulesFiltersParams>>({
    network,
    config: {
      onSuccess: (_, variables) => {
        onSuccess?.();
        closeSnackbar();
        const vars = variables as BulkActionRulesParams<TRulesFiltersParams>;
        const rulesArchivedMessage = `${vars?.rules_ids && vars.rules_ids.length > 1 ? 'Rules' : 'Rule'} ${vars?.status}`;
        onShowAlert(
          vars.status === 'archived'
            ? rulesArchivedMessage
            : `${(vars.status as RuleStatusValue) === 'archived' ? 'Rules archived' : 'Rules unarchived as'} ${vars?.status}`,
          {
            delayAlert: 5000,
            onUndo:
              tab && vars.status !== tab && tab !== 'all'
                ? () => {
                    editRulesMutation.mutate({
                      ...vars,
                      status: tab as RuleStatusValue,
                    });
                    onShowInfoAlert(
                      vars.status === 'archived'
                        ? vars.status === 'archived'
                          ? `Unarchiving rules`
                          : 'Archiving rules'
                        : `Archiving rules`,
                      {
                        isPending: true,
                        delayAlert: 10000,
                      }
                    );
                  }
                : undefined,
          }
        );
      },
      onError: (err) => {
        closeSnackbar();
        reset();
        onShowInfoAlert(err.cause?.message ?? 'Error');
        setErrorMessage(err.cause?.message);
        onError?.();
      },
    },
  });

  const onArchiveManually = useCallback(() => {
    closeSnackbar();
    editRulesMutation.mutate({
      status: tab === 'archived' ? status : 'archived', // toggle between archived and unarchive
      ...rulesQueryParams,
    } as BulkActionRulesParams<TRulesFiltersParams>);
  }, [rulesQueryParams, editRulesMutation, status, tab]);

  return {
    errorMessage,
    isChosenRules,
    isPending: editRulesMutation.isPending,
    isSuccess: editRulesMutation.isSuccess,
    rulesIds,
    onArchiveManually,
  };
};

export default useBulkArchive;
