import { apiClient } from 'lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import type { EditFlowParams } from './types';
import { isAxiosError } from 'axios';
import { FB_RULES_LIST_KEY } from './getRulesList';

export const editFlow = async ({ id, ...params }: EditFlowParams): Promise<{}> => {
  const res = await apiClient.put<{}>(`/flows/${id}`, params);
  if (isAxiosError(res)) {
    const error = new Error('Edit flow error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseEditFlowOptions {
  config?: MutationConfig<typeof editFlow>;
}

export const useEditFlow = ({ config }: UseEditFlowOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: [FB_RULES_LIST_KEY],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: editFlow,
  });
};
