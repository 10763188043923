import type { ActionIndexProps } from '../types';
import tasksFormStore from '../stores/tasksFormStore';
import { DUPLICATE_ACTIONS, REPLACE_ACTIONS, REST_ACTIONS, SINGLE_ACTIONS, STRING_ACTIONS } from '../constants';

const actionFieldsVisibility = ({ indexAction, indexTask, indexVariant }: ActionIndexProps) => {
  const storedActionValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.action;

  return {
    isCountVisible: DUPLICATE_ACTIONS.includes(storedActionValue as string),
    isSubActionVisible:
      DUPLICATE_ACTIONS.includes(storedActionValue as string) && !SINGLE_ACTIONS.includes(storedActionValue as string),
    isValueVisible:
      !SINGLE_ACTIONS.includes(storedActionValue as string) && !REPLACE_ACTIONS.includes(storedActionValue as string),
    isValueToggleVisible:
      REST_ACTIONS.includes(storedActionValue as string) && !REPLACE_ACTIONS.includes(storedActionValue as string),
    isOnlyStringValue:
      STRING_ACTIONS.includes(storedActionValue as string) || DUPLICATE_ACTIONS.includes(storedActionValue as string),
    isSubValueToggleVisible: REST_ACTIONS.includes(storedActionValue as string),
    isUnitVisible: REST_ACTIONS.includes(storedActionValue as string),
    isReplaceInName: REPLACE_ACTIONS.includes(storedActionValue as string),
    isSingleActionValue: SINGLE_ACTIONS.includes(storedActionValue as string),
  };
};

export default actionFieldsVisibility;
