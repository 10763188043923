import type { FC } from 'react';
import { useCallback } from 'react';
import { Cancel, Search } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import useListParams from 'hooks/useListParams';
import { useRuleGroups, useTeams, useStatuses } from '../api';
import { useActionsType } from '../../api/getActionsType';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getReferencesOptions } from '../api/getRefences';
import { WEEK_DAYS } from '../../../../constants';

const QValues = () => {
  const { setValue } = useFormContext();
  const { q, setQ } = useListParams();

  return q?.length ? (
    <Chip
      size="small"
      label={q}
      icon={<Search sx={(t) => ({ width: 18, color: t.palette.primary.main, ml: 1 })} />}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setValue('q', '');
        setQ(undefined);
      }}
      sx={{ mb: 1 }}
    />
  ) : null;
};
const WeekDaysValues = () => {
  const { setValue } = useFormContext();
  const { weekday, setWeekday } = useListParams();

  const weekdays = Object.entries(WEEK_DAYS).map(([key, value]) => ({ value: Number(key), label: value }));

  return weekdays
    .filter((day) => (weekday as number[]).includes(day.value))
    .map((day) => (
      <Chip
        key={`WeekDayValue:${day.value}.${day.label}`}
        label={day.label}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentDays = weekday.filter((i) => i !== day.value);
          setValue('weekday', currentDays);
          setWeekday(currentDays);
        }}
      />
    ));
};

const FromValues = () => {
  const { timeFrom, setFrom } = useListParams();
  const { setValue } = useFormContext();

  return (
    <Chip
      key={`Filter:fromValues:${timeFrom}`}
      label={`From: ${timeFrom}`}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setFrom(undefined);
        setValue('timeFrom', undefined);
      }}
    />
  );
};
const ToValues = () => {
  const { timeTo, setTo } = useListParams();
  const { setValue } = useFormContext();

  return (
    <Chip
      key={`Filter:toValues:${timeTo}`}
      label={`To: ${timeTo}`}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setTo(undefined);
        setValue('timeTo', undefined);
      }}
    />
  );
};
const ActionValues = () => {
  const { setValue } = useFormContext();
  const { action, setAction } = useListParams();

  const { data: actions } = useActionsType({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });

  return actions
    .filter((item) => action.map(Number).includes(Number(item.value)))
    .map((item) => (
      <Chip
        key={`ActionValues:${item.value}.${item.label}`}
        label={item.label}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentActions = action.map(Number).filter((i) => i !== Number(item.value));
          setValue('action', currentActions);
          setAction(currentActions);
        }}
      />
    ));
};
const LevelsValues = () => {
  const { setValue } = useFormContext();
  const { level, setLevel } = useListParams();

  const { data: levels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  return levels
    .filter((item) => (level as string[]).includes(item.value))
    .map((item) => (
      <Chip
        key={`LevelsValues:${item.value}.${item.label}`}
        label={item.label}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentLevels = level.filter((i) => i !== item.value);
          setValue('level', currentLevels);
          setLevel(currentLevels);
        }}
      />
    ));
};
const StatusValues = () => {
  const { setValue } = useFormContext();
  const { tab, statusState, setStatusState } = useListParams();

  const { data: statuses } = useStatuses({
    params: {
      tab: 'all',
    },
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })).filter((i) => i.value !== tab),
    },
  });

  return statuses
    .filter((item) => (statusState as string[]).includes(item.value))
    .map((item) => (
      <Chip
        key={`StatusValues:${item.value}.${item.label}`}
        label={item.label}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentLevels = statusState.filter((i) => i !== item.value);
          setValue('status', currentLevels);
          setStatusState(currentLevels);
        }}
      />
    ));
};
const TeamValues = () => {
  const { setValue } = useFormContext();
  const { team, setTeam } = useListParams();

  const { data: teams } = useTeams({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });

  return teams
    .filter((item) => team.map(Number).includes(Number(item.value)))
    .map((item) => (
      <Chip
        key={`TeamValues:${item.value}.${item.label}`}
        label={item.label}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentLevels = team.map(Number).filter((i) => i !== Number(item.value));
          setValue('team', currentLevels);
          setTeam(currentLevels);
        }}
      />
    ));
};
const ArchiveDateValues = () => {
  const { setValue } = useFormContext();
  const { archiveDate, setArchiveDate } = useListParams();

  return (
    <Chip
      size="small"
      label={archiveDate}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setArchiveDate(undefined);
        setValue('archiveDate', '');
      }}
      sx={{ mb: 1 }}
    />
  );
};
const PauseDateValues = () => {
  const { setValue } = useFormContext();
  const { pauseDate, setPauseDate } = useListParams();

  return (
    <Chip
      size="small"
      label={pauseDate}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setPauseDate(undefined);
        setValue('pauseDate', '');
      }}
      sx={{ mb: 1 }}
    />
  );
};
const RuleGroupsValues = () => {
  const { setValue } = useFormContext();
  const { groups, setGroups } = useListParams();

  const { data: ruleGroups } = useRuleGroups({
    config: {
      select: (data) => data.filter((g) => groups?.includes(`${g.id}`)),
    },
  });

  return ruleGroups.map((item) => (
    <Chip
      key={`RuleGroupsValue:${item.id}.${item.name}`}
      label={item.name}
      deleteIcon={<Cancel />}
      onDelete={() => {
        const currentGroups = groups?.filter((i) => i !== `${item.id}`);
        setValue('groups', currentGroups);
        setGroups(currentGroups);
      }}
    />
  ));
};

interface SearchValuesButtonProps {
  onClick: () => void;
  title: string;
}

const SearchValuesButton: FC<SearchValuesButtonProps> = ({ onClick, title }) => {
  return (
    <Button
      variant="text"
      color="inherit"
      sx={(t) => ({
        borderRadius: t.borderRadius.sm,
        backgroundColor: 'transparent',
        marginRight: 1,
        height: 24,
      })}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const FilterValues: FC = () => {
  const { reset } = useFormContext();
  const {
    tab,
    q,
    setQ,
    weekday,
    setWeekday,
    action,
    setAction,
    level,
    setLevel,
    statusState,
    setStatusState,
    team,
    setTeam,
    timeFrom,
    setFrom,
    timeTo,
    setTo,
    pauseDate,
    setPauseDate,
    archiveDate,
    setArchiveDate,
    setPlatform,
    setListId,
    setGroups,
    project,
    groups,
  } = useListParams();

  const handleResetAll = useCallback(() => {
    setQ(undefined);
    setWeekday(undefined);
    setAction(undefined);
    setLevel(undefined);
    setStatusState(undefined);
    setTeam(undefined);
    setFrom(undefined);
    setTo(undefined);
    setPauseDate(undefined);
    setArchiveDate(undefined);
    setPlatform(undefined);
    setListId(undefined);
    setGroups(undefined);
    reset({
      q: '',
      days: [],
      today: false,
      tomorrow: false,
      archiveDate: '',
      pauseDate: '',
      timeTo: '',
      timeFrom: '',
      action: [],
      level: [],
      statusState: [],
      team: [],
      platform: undefined,
      listId: [],
      groups: [],
      project,
    });
  }, [
    reset,
    setQ,
    setWeekday,
    setFrom,
    setTo,
    setPauseDate,
    setArchiveDate,
    setAction,
    setLevel,
    setStatusState,
    setTeam,
    setListId,
    setPlatform,
    setGroups,
    project,
  ]);

  const isResetAll = [
    q,
    weekday,
    action,
    level,
    statusState.filter((i) => i !== tab),
    team,
    timeFrom,
    timeTo,
    pauseDate,
    archiveDate,
    groups,
  ].filter((i) => !isEmpty(i)).length;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 0.5 }}>
      {isResetAll ? <SearchValuesButton onClick={handleResetAll} title="Reset all" /> : null}
      <QValues />
      {!isEmpty(weekday) && <WeekDaysValues />}
      {!isEmpty(timeFrom) && <FromValues />}
      {!isEmpty(timeTo) && <ToValues />}
      {!isEmpty(archiveDate) && <ArchiveDateValues />}
      {!isEmpty(pauseDate) && <PauseDateValues />}
      {!isEmpty(action) && <ActionValues />}
      {!isEmpty(level) && <LevelsValues />}
      {!isEmpty(statusState) && <StatusValues />}
      {!isEmpty(team) && <TeamValues />}
      {!isEmpty(groups) && <RuleGroupsValues />}
    </Box>
  );
};

export default FilterValues;
