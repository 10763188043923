import type { FC } from 'react';
import { useAccessModify } from '../hooks';
import BulkArchiveMenuItem from './BulkArchiveMenuItem';
import type { BulkMenuItemProps } from '../types';

const ArchiveRulesMenuItem: FC<Pick<BulkMenuItemProps, 'network' | 'closeMenu'>> = ({ network, closeMenu }) => {
  const { accessRules } = useAccessModify();
  return accessRules.archive ? <BulkArchiveMenuItem network={network} closeMenu={closeMenu} label="Archive" /> : null;
};

export default ArchiveRulesMenuItem;
