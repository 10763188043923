import type { FC } from 'react';
import type { BulkMenuItemProps } from '../types';
import { useAccessModify } from '../hooks';
import BulkArchiveMenuItem from './BulkArchiveMenuItem';

const UnarchiveRulesMenuItem: FC<Pick<BulkMenuItemProps, 'network' | 'closeMenu' | 'status'>> = ({
  network,
  closeMenu,
  status,
}) => {
  const { accessRules } = useAccessModify();
  return accessRules.unarchive ? (
    <BulkArchiveMenuItem network={network} closeMenu={closeMenu} status={status} label={`Unarchive as ${status}`} />
  ) : null;
};

export default UnarchiveRulesMenuItem;
