import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import type { BulkActionRulesParams } from '../types';
import { isEmpty, isNumber, omit, pickBy } from 'lodash-es';
import { useFilterQueryValues } from 'hooks';

const useRulesQueryParams = <TRulesFiltersParams>(): Pick<
  BulkActionRulesParams<TRulesFiltersParams>,
  'filters' | 'project_id' | 'rules_ids'
> => {
  const filtersParams = useFilterQueryValues();
  const { project } = useListParams();
  const { rulesIds, isAllRulesChosen } = useChosenRules();

  const projectId = project === 'all_projects' ? undefined : parseInt(project);

  const filtersValues = {
    filters: {
      ...pickBy(
        omit(filtersParams, ['order_by', 'desc', 'limit', 'offset', 'paginationRelatedParams']), // exclude fields
        (value) => isNumber(value) || !isEmpty(value) // remove empty fields
      ),
      ...(projectId ? { project_id: projectId } : {}),
    },
  };

  return {
    ...filtersValues,
    rules_ids: isAllRulesChosen ? [] : rulesIds,
  } as Pick<BulkActionRulesParams<TRulesFiltersParams>, 'filters' | 'project_id' | 'rules_ids'>;
};

export default useRulesQueryParams;
