import { useAccessModify } from '../hooks';
import { useAnchorMenu } from 'hooks';
import { IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import type { FC, ReactElement } from 'react';

type ChildrenFunction = (props: { closeMenu: () => void }) => ReactElement;

const RulesTableMenuContainer: FC<{children: ReactElement | ChildrenFunction}> = ({children}) => {
  const { accessRules } = useAccessModify();
  const { anchor, isOpen, handleCloseMenu, handleOpenMenu } = useAnchorMenu();

  return (
    <>
      <IconButton disabled={!accessRules.bulk} onClick={handleOpenMenu} sx={{ width: 36, height: 36 }}>
        <MoreVert sx={{ fontSize: 24 }} />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: 220,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableAutoFocusItem
        disableAutoFocus
      >
        {typeof children === 'function' ? children({ closeMenu: handleCloseMenu }) : children}
      </Menu>
    </>
  );
};

export default RulesTableMenuContainer;
