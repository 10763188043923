import { Box, Divider, Grid, IconButton, List, ListItem, ListItemButton, Skeleton, Typography } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useModal } from 'hooks';
import { getPersistedData, persistData } from 'utils/persist';
import type { FC } from 'react';
import { useCallback } from 'react';

const RulesSidePanelSkeleton: FC<{ title: string }> = ({ title }) => {
  const { toggle: togglePlatforms, isOpen: isOpenPlatforms } = useModal(
    getPersistedData('directoryFilterVisibility') ?? false
  );

  const handleToggle = useCallback(() => {
    persistData('directoryFilterVisibility', !isOpenPlatforms);
    togglePlatforms();
  }, [persistData, togglePlatforms]);

  return (
    <Grid
      item
      sx={(t) => ({
        maxWidth: !isOpenPlatforms ? 30 : 250,
        transition: 'all 0.2s',
        willChange: 'auto',
        py: 1.5,
        px: !isOpenPlatforms ? 0 : 1.5,
        flex: 1,
        borderTopLeftRadius: 12,
        borderTop: `1px solid ${t.palette.grey[300]}`,
        borderLeft: `1px solid ${t.palette.grey[300]}`,
        overflow: 'hidden',
        overflowY: 'auto',
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        {isOpenPlatforms && (
          <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500 })}>{title}</Typography>
        )}
        <IconButton onClick={handleToggle}>
          {!isOpenPlatforms ? <MenuIcon sx={{ fontSize: 18 }} /> : <MenuOpenIcon sx={{ fontSize: 22 }} />}
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          visibility: isOpenPlatforms ? 'visible' : 'hidden',
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1,
                '&': { borderRadius: '8px' },
              })}
            >
              <Skeleton width={140} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
              <Skeleton width={38} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ my: 2 }} />
          {[...Array(14).keys()].map((_, index) => (
            <ListItem key={`filterPlatformsSkeletonItem_${index}`} disablePadding>
              <ListItemButton
                sx={(t) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 1,
                  '&': { borderRadius: '8px' },
                })}
              >
                <Skeleton width={140} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                <Skeleton width={38} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Grid>
  );
};

export default RulesSidePanelSkeleton;
