import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useEditRules } from '../api/editRules';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import useRulesQueryParams from './useRulesQueryParams';
import type { BulkActionRulesParams } from '../types';
import type { Network } from 'types/shared';

const useBulkPause = <TRulesFiltersParams>({
  network,
  onSuccess,
  onError,
}: {
  network: Network;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setValue, reset } = useFormContext();
  const { tab } = useListParams();
  const { rulesIds, isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams<TRulesFiltersParams>();

  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const editRulesMutation = useEditRules<BulkActionRulesParams<TRulesFiltersParams>>({
    network,
    config: {
      onSuccess: (_, variables) => {
        const vars = variables as BulkActionRulesParams<TRulesFiltersParams>;
        closeSnackbar();
        setValue('all_rules_switched', tab === 'active');
        onShowAlert(vars.status === 'active' ? 'Rules unpaused' : 'Rules paused', {
          delayAlert: 5000,
          onUndo:
            vars?.status !== tab
              ? () => {
                  const isActive = vars.status === 'active';
                  editRulesMutation.mutate({
                    ...vars,
                    status: isActive ? 'paused' : 'active',
                  });
                  onShowInfoAlert(isActive ? 'Pausing rules' : 'Unpausing rules', {
                    isPending: true,
                    delayAlert: 10000,
                  });
                }
              : undefined,
        });
        reset();
        onSuccess?.();
      },
      onError: (err) => {
        reset();
        onShowInfoAlert(err.cause?.message ?? 'Error');
        setErrorMessage(err.cause?.message);
        onError && onError();
      },
    },
  });

  const onPause = useCallback(() => {
    closeSnackbar();
    editRulesMutation.mutate({
      status: tab === 'active' ? 'paused' : 'active',
      ...rulesQueryParams,
    } as BulkActionRulesParams<TRulesFiltersParams>);
  }, [rulesQueryParams, editRulesMutation, tab]);

  return {
    errorMessage,
    isChosenRules,
    isPending: editRulesMutation.isPending,
    isSuccess: editRulesMutation.isSuccess,
    rulesIds,
    onPause,
  };
};

export default useBulkPause;
