import { Box, Typography, Divider, Button, IconButton, Tooltip } from '@mui/material';
import type { FC } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import filterStore from '../stores/filterStore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { observer } from 'mobx-react-lite';
import RuleEditFilterForm from './RuleEditFilterForm';
import { useRef } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { RuleFilter } from '../../types';

interface RuleEditFilterBlockProps {
  groupIndex: number;
  filterGroup: RuleFilter[];
}

const RuleEditFilterGroup: FC<RuleEditFilterBlockProps> = observer(({ groupIndex, filterGroup }) => {
  const { onShowInfoAlert } = useEnqueueSnackbar();
  const addFilterGroupButtonRef = useRef<HTMLButtonElement>(null);

  const duplicateFilterGroup = () => {
    filterStore.duplicateFilterGroup(groupIndex);
    onShowInfoAlert('Filter group copied');
    if (addFilterGroupButtonRef.current) {
      addFilterGroupButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const removeFilterGroup = () => {
    const result = filterStore.removeFilterGroup(groupIndex);
    if (result) {
      onShowInfoAlert('Filter group deleted');
    } else {
      onShowInfoAlert(`You can't delete last filter group`);
    }
  };

  const addFilter = () => {
    const isAdded = filterStore.addFilter(groupIndex);
    if (!isAdded) {
      onShowInfoAlert('Please finish editing last filter before adding new');
    }
  };

  return (
    <Box id={`fg-${groupIndex}`}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box
          sx={(t) => ({
            p: 0.5,
            background: t.palette.primary.main,
            display: 'inline-flex',
            alignItems: 'center',
            color: 'white',
            borderRadius: 1,
          })}
        >
          <FilterListIcon fontSize="large" sx={{ fontSize: 24, mr: 1 }} />
          <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize })}>Filter group {groupIndex + 1}</Typography>
        </Box>
        <Box>
          <Tooltip title="Duplicate filter group" placement="top" arrow>
            <IconButton onClick={duplicateFilterGroup}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete filter group" placement="top" arrow>
            <IconButton onClick={removeFilterGroup}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '60%' }}>
        {filterGroup.map((f, index) => (
          <RuleEditFilterForm key={f.field + index} index={index} groupIndex={groupIndex} />
        ))}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button ref={addFilterGroupButtonRef} sx={{ py: 2.5, mr: 3 }} onClick={addFilter}>
          <AddIcon sx={{ mr: 1 }} /> Add filter
        </Button>
        <Box sx={{ display: 'flex' }}>
          <InfoOutlinedIcon sx={(t) => ({ fontSize: 16, mr: 0.5, color: t.palette.text.secondary })} />
          <Typography color="text.secondary" variant="body2">
            Filter for &rdquo;NORULE&rdquo; is enabled by default
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
    </Box>
  );
});

export default RuleEditFilterGroup;
